import React from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import MainSliderProgressBar from "../MainSliderProgressBar";
var MainSliderNavThumb = function (props) {
    var fields = props.fields, nextTextLabel = props.nextTextLabel;
    var labelThumb = fields.labelThumb, titleThumb = fields.titleThumb, imageThumb = fields.imageThumb;
    var _labelThumb = nextTextLabel
        ? {
            value: nextTextLabel,
            editable: "",
        }
        : labelThumb;
    return (React.createElement("div", { className: "c-MainSlider__navItem" },
        React.createElement(MainSliderProgressBar, { autoplay: fields.durationSlide }),
        React.createElement("div", { className: "c-MainSlider__thumbBlock" },
            React.createElement("div", { className: "c-MainSlider__thumbImgBlock" },
                React.createElement(Image, { field: imageThumb })),
            React.createElement("div", { className: "c-MainSlider__thumbTitleBlock" },
                React.createElement(RichText, { className: "c-MainSlider__label", field: _labelThumb, tag: "span" }),
                React.createElement(RichText, { className: "c-MainSlider__navTitle", field: titleThumb, tag: "span" })))));
};
export default MainSliderNavThumb;
