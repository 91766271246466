export var loadExternalScript = function (_a) {
    var src = _a.src, async = _a.async;
    if (typeof window !== 'undefined') {
        return new Promise(function (resolve, reject) {
            var newScript = document.createElement('script');
            newScript.src = src;
            newScript.type = 'text/javascript';
            newScript.async = async;
            newScript.onload = resolve;
            newScript.onerror = reject;
            document.body.appendChild(newScript);
        });
    }
    else {
        return new Promise(function (resolve, reject) {
            reject();
        });
    }
};
