var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import "./styles.scss";
var HeadingImage = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, imgMain = _a.imgMain, position = _a.position;
    var _position = position.value;
    return (React.createElement(PlaceholderComponent, __assign({}, props, { withBg: true, withoutSpaces: true }),
        React.createElement("div", { className: "c-HeadingImage c-HeadingImage--" + _position },
            React.createElement("div", { className: "c-HeadingImage__heading" }, heading && React.createElement(ComponentHeading, { tag: headingTag, field: heading })),
            React.createElement("div", { className: "c-HeadingImage__image" }, imgMain && React.createElement(Image, { loading: "lazy", media: imgMain })))));
};
export default HeadingImage;
