var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import Swiper from "react-id-swiper";
// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// internal_modules
import ImageTextSliderItem from "./ImageTextSliderItem";
// fragments
import "../../assets/styles/components/Swiper.scss";
import "./styles.scss";
SwiperCore.use([Navigation, Pagination, A11y]);
var ImageTextSlider = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, imageTextSlides = _a.imageTextSlides;
    var isMultipleSlides = imageTextSlides.length > 1;
    var pagination = isMultipleSlides
        ? { el: ".swiper-pagination", dynamicBullets: true, clickable: true }
        : false;
    var navigation = isMultipleSlides
        ? {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }
        : false;
    var swiperParams = {
        breakpoints: {
            481: {
                spaceBetween: 0,
            },
        },
        pagination: pagination,
        navigation: navigation,
        slidesPerView: 1,
        initialSlide: 1,
        centeredSlides: true,
        slideActiveClass: "c-ImageTextSlider--active",
        containerClass: "c-ImageTextSlider__container",
        slideNextClass: "c-ImageTextSlider--next",
        slidePrevClass: "c-ImageTextSlider--prev",
    };
    return (React.createElement(PlaceholderComponent, __assign({ withoutSpaces: false, withContainer: true, withBg: true, customSpaces: true }, props),
        heading && headingTag && (React.createElement(ComponentHeading, { tag: headingTag, field: heading, className: "align-center" })),
        React.createElement("div", { className: "c-ImageTextSlider" }, imageTextSlides && (React.createElement(Swiper, __assign({}, swiperParams), imageTextSlides.map(function (item, index) {
            var _a = item.fields, textTitle = _a.textTitle, textDescription = _a.textDescription, imageSlide = _a.imageSlide;
            var key = item + "-" + index;
            var withoutImg = !!imageSlide;
            var withoutImgItemClassName = "c-ImageTextSlider__item" + (!withoutImg ? "--withoutImg" : "") + " " + (getFieldValue(props.fields, "rightPosition")
                ? "c-ImageTextSlider__item--right"
                : "");
            var withoutImgTextWrapperClassName = "c-ImageTextSlider__textWrapper" + (!withoutImg ? "--withoutImg" : "") + " " + (getFieldValue(props.fields, "rightPosition")
                ? "c-ImageTextSlider__textWrapper--right"
                : "") + " ";
            return (React.createElement("div", { key: key, className: "c-ImageTextSlider__slide" },
                React.createElement("div", { className: "c-ImageTextSlider__item " + withoutImgItemClassName },
                    imageSlide && (React.createElement("div", { className: "c-ImageTextSlider__imageWrapper" },
                        React.createElement(Image, { className: "c-ImageTextSlider__imageItem", media: imageSlide }))),
                    React.createElement(ImageTextSliderItem, { title: textTitle, description: textDescription, className: withoutImgTextWrapperClassName }))));
        }))))));
};
export default ImageTextSlider;
