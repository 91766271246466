/* eslint-disable import/first */
// node_modules
import React from "react";
import { RichText, Image, getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
import { generalLinkIsEmpty } from "../../helpers/app/jss/fieldTypes/GeneralLink";
import BtnElevator from "../../shared/BtnElevator";
// fragments
import "./styles.scss";
var TouchCTA = function (props) {
    var fields = props.fields;
    var fieldImgUrl = getFieldValue(fields, "imgurl");
    var fieldText = getFieldValue(fields, "text");
    var fieldButton = getFieldValue(fields, "button");
    var fieldNoFollow = getFieldValue(fields, "noFollow");
    return (React.createElement("div", { className: "c-FlyingCTA" },
        React.createElement("div", { className: "c-FlyingCTA__imgCont" }, fieldImgUrl && (React.createElement(Image, { loading: "lazy", field: fields.imgurl, className: "c-FlyingCTA__img" }))),
        React.createElement("div", { className: "c-FlyingCTA__content" },
            fieldText && (React.createElement(RichText, { field: fields.text, tag: "p", className: "c-FlyingCTA__text" })),
            fieldButton && !generalLinkIsEmpty(fieldButton) && (React.createElement(BtnElevator, { field: fields.button.value, noFollow: fieldNoFollow })))));
};
export default TouchCTA;
