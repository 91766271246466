/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { bgGradientFromConfiguration } from "../../helpers/app/jss/fields/Gradient";
import { themeClassName } from "../../helpers/app/jss/fields/Theme";
// Shared components
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import CaseStudyShortInfoItem from "./CaseStudyShortInfoItem";
// fragments
import "./styles.scss";
var CaseStudyShortInfo = function (props) {
    var fields = props.fields;
    var bgColorGradientStep = fields.bgColorGradientStep;
    // Get value (class gradient step) from 'bgColorGradientStep' field
    var _bgColorGradientStepFields = bgColorGradientStep
        ? bgColorGradientStep
        : null;
    var _bgGradientClassName = "";
    if (_bgColorGradientStepFields) {
        var _gradientFieldValue = bgGradientFromConfiguration(_bgColorGradientStepFields);
        _bgGradientClassName = _gradientFieldValue.value; // gradient class
    }
    // Get class for color texts from 'theme' field
    var _themeClassName = themeClassName(fields);
    var divParentClasses = "c-CaseStudyShortInfo" + _themeClassName + " align-center";
    var $component = {
        backgroundClass: _bgGradientClassName,
        elementClasses: divParentClasses,
    };
    return (React.createElement(PlaceholderComponent, __assign({ componentSettings: $component }, props), fields.listItems && (React.createElement("ul", { className: "c-CaseStudyShortInfo__list" }, fields.listItems.map(function (item, index) { return (React.createElement(CaseStudyShortInfoItem, __assign({ key: "CaseStudyShortInfoItem-" + index }, item))); })))));
};
export default CaseStudyShortInfo;
