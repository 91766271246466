import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

export function bgGradientTypeClassName(propsFields) {
  const stepGradient = getFieldValue(propsFields, "step");
  const gradientType = stepGradient ? `step${stepGradient}` : "base";

  return `gradient--${gradientType}`;
}

export function bgGradientFromConfiguration(gradientField) {
  return gradientField && gradientField.fields
    ? _bgGradientFromConfiguration(gradientField.fields)
    : null;
}

function _bgGradientFromConfiguration(gradientFields) {
  return {
    value: getFieldValue(gradientFields, "value") || "",
    textColor: getFieldValue(gradientFields, "textColor") || "",
    textGradient: getFieldValue(gradientFields, "textGradient") || "",
    backgroundColor: getFieldValue(gradientFields, "backgroundColor") || "",
  };
}
