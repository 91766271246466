var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
// internal_modules
import ImageTextTile from "../ImageTextTile";
// fragments
import "./styles.scss";
var ImageTextTilesList = function (props) {
    return props.data.length > 0 && (React.createElement("div", { className: "c-ImageTextTiles__list" },
        React.createElement("ul", { className: "reset" }, props.data.map(function (item, index) {
            return React.createElement(ImageTextTile, __assign({}, item, { key: item.id + "-" + index }));
        }))));
};
export default ImageTextTilesList;
