import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import { headingTagField } from "../ComponentHeading/index";
import { componentId as getComponentId } from "../../../helpers/app/jss/renderings/index.js";
import { cleanupEmptyItemsFromArray } from "../../../helpers/app/utils/array/index";
// import Loadable from 'react-loadable';
// import Loading from '../shared/Loading';

import "./styles.scss";

const PlaceholderComponent = ({
  children,
  rendering = null,
  componentSettings = null,
  withContainer = true,
  customSpaces = false,
  withoutSpaces = false,
  withBg = false,
}) => {
  // console.log("PlaceholderComponent", rendering);

  let placeholderComponentTag = 'div';

  if (getFieldValue(rendering.fields, 'heading')) {
    const _headingTagField = headingTagField(rendering.fields.headingTag);
    if (_headingTagField !== null && _headingTagField !== 'span') {
      placeholderComponentTag = 'section';
    }
  }

  // withContainer
  if (typeof rendering.fields.withContainer === 'object') {
    withContainer = getFieldValue(rendering.fields, 'withContainer');
  }

  const componentId = getComponentId(rendering);

  // Navigation ID for hash navigation
  const navigationdId = getFieldValue(rendering.fields, 'navigationid') || null;

  // ---
  // Classes to wrapper of component
  // ---
  let componentWrapperClasses = ['component'];

  // Margins
  // get class of margin top
  const marginClassTop = getFieldValue(rendering.fields.marginTop, 'classname');
  componentWrapperClasses.push(marginClassTop);

  // get class of margin bottom
  const marginClassBottom = getFieldValue(
    rendering.fields.marginBottom,
    'classname'
  );
  componentWrapperClasses.push(marginClassBottom);

  // Show/hide margins of component
  const componentwithoutspaces = getFieldValue(
    rendering.fields,
    'componentwithoutspaces'
  );

  // if component has 'componentwithoutspaces' field regardless of value
  withoutSpaces =
    !withoutSpaces && componentwithoutspaces
      ? componentwithoutspaces
      : withoutSpaces;

  withoutSpaces = withoutSpaces ? 'component--nospaces' : '';
  componentWrapperClasses.push(withoutSpaces);

  // Wrapper classes of component
  const _componentWrapperClasses =
    componentSettings && componentSettings.wrapperClasses
      ? componentSettings.wrapperClasses
      : '';
  componentWrapperClasses.push(_componentWrapperClasses);

  // ---
  // Comonent Settings
  // ---
  let componentContainerClasses = ['container'];

  // Use custom spaces (margins and paddings) for PlaceholderComponent
  customSpaces = customSpaces ? 'container--custom' : '';
  componentContainerClasses.push(customSpaces);

  // If component has inner custom classes
  let _componentInnerClasses =
    componentSettings && componentSettings.elementClasses
      ? componentSettings.elementClasses
      : {};
  // If component has inline styles
  const _componentInnerStyle =
    componentSettings && componentSettings.elementStyle
      ? componentSettings.elementStyle
      : {};
  // If component has image on background
  const _componentBackgroundCover =
    componentSettings && componentSettings.backgroundCover
      ? componentSettings.backgroundCover
      : false;
  // If component has classes for background color/gradient
  const _componentBackgroundClass =
    componentSettings && componentSettings.backgroundClass
      ? componentSettings.backgroundClass
      : false;

  const _componentWithBgCover = _componentBackgroundCover
    ? 'component--bgcover'
    : '';
  componentWrapperClasses.push(_componentWithBgCover);

  // Grey background color of component
  let bgColorGrey = getFieldValue(rendering.fields, 'bgColorGrey');
  // if component has 'bgColorGrey' field regardless of value
  withBg = !withBg && bgColorGrey ? bgColorGrey : withBg;

  const _componentWithBgClass =
    _componentBackgroundClass || (withBg ? 'component--bg' : '');

  componentWrapperClasses.push(_componentWithBgClass);

  // remove empty items
  componentWrapperClasses = cleanupEmptyItemsFromArray(componentWrapperClasses);
  componentContainerClasses = cleanupEmptyItemsFromArray(
    componentContainerClasses
  );
  if (Array.isArray(_componentInnerClasses)) {
    _componentInnerClasses = cleanupEmptyItemsFromArray(_componentInnerClasses);
  }

  return React.createElement(
    placeholderComponentTag,
    {className: componentWrapperClasses, id: componentId},
    withContainer ? (
      <div id={navigationdId} className={componentContainerClasses}>
        <div className='container__inner'>{children}</div>
      </div>
    ) : componentSettings ? (
      <div className={_componentInnerClasses} style={_componentInnerStyle}>
        <div id={navigationdId} className={componentContainerClasses}>
          <div className='container__inner'>{children}</div>
        </div>
      </div>
    ) : navigationdId ? (
      <div id={navigationdId}>{children}</div>
    ) : (
      children
    )
  );
};

export default PlaceholderComponent;
