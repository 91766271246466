var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import Swiper from "react-id-swiper";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import CarouselTilesList from "./CarouselTilesList";
import "../../assets/styles/components/Swiper.scss";
import "./styles.scss";
var CarouselTiles = function (props) {
    var carouselParams = {
        slideClass: "c-CarouselTile",
        freeMode: true,
        slidesPerView: "auto",
        slideActiveClass: "c-CarouselTile--white",
        containerClass: "c-CarouselTiles__tiles",
    };
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, brands = _a.brands;
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false }, props),
        React.createElement("div", { className: "c-CarouselTiles" },
            heading && (React.createElement(ComponentHeading, { tag: headingTag, field: heading, className: "align-center" })),
            brands && (React.createElement(Swiper, __assign({}, carouselParams), brands && React.createElement(CarouselTilesList, { data: brands }))))));
};
export default CarouselTiles;
