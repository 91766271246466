// node_modules
import React from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";

// Shared components
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import Swiper from "react-id-swiper";

// Partials of component
import "../../assets/styles/components/Swiper.scss";
import "./styles.scss";

const CaseStudyMobileVisual = (props) => {
  let swiperParams = {
    breakpoints: {
      768: {
        spaceBetween: 30,
      },
    },
    slidesPerView: "auto",
    initialSlide: 1,
    centeredSlides: true,
    spaceBetween: 120,
    slideActiveClass: "c-CaseStudyMobileVisual--active",
    containerClass: "c-CaseStudyMobileVisual__mobile",
    slideNextClass: "c-CaseStudyMobileVisual--next",
    slidePrevClass: "c-CaseStudyMobileVisual--prev",
  };

  const { fields } = props;
  return (
    <PlaceholderComponent {...props}>
      {fields.listItems && (
        <React.Fragment>
          <div className="c-CaseStudyMobileVisual__desktop">
            {fields.listItems.map((item, index) => {
              const key = `${item.fields.id}-${index}`;
              return (
                <div key={key} className="c-CaseStudyMobileVisual__slide">
                  <div className="c-CaseStudyMobileVisual__item">
                    <Image
                      className="c-CaseStudyMobileVisual__item-image"
                      media={item.fields.imgurl}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="c-CaseStudyMobileVisual__mobile">
            <Swiper {...swiperParams}>
              {fields.listItems.map((item, index) => {
                const key = `${item.fields.id}-${index}`;
                return (
                  <div key={key} className="c-CaseStudyMobileVisual__slide">
                    <div className="c-CaseStudyMobileVisual__item">
                      <Image
                        className="c-CaseStudyMobileVisual__item-image"
                        media={item.fields.imgurl}
                      />
                    </div>
                  </div>
                );
              })}
            </Swiper>
          </div>
        </React.Fragment>
      )}
    </PlaceholderComponent>
  );
};

export default CaseStudyMobileVisual;
