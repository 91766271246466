import React from "react";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import "./styles.scss";
var IconsTextsItem = function (props) {
    var data = props.data;
    var fields = data.fields;
    var iconTextDescription = fields.iconTextDescription, iconTextImage = fields.iconTextImage;
    return (React.createElement("li", { className: "c-IconsTexts__item" },
        iconTextImage && (React.createElement("span", { className: "c-IconsTexts__image", "aria-hidden": "true" },
            React.createElement(Image, { field: iconTextImage }))),
        iconTextDescription && (React.createElement(RichText, { tag: "p", className: "c-IconsTexts__text", field: iconTextDescription }))));
};
export default IconsTextsItem;
