/* eslint-disable import/first */
// node_moduels
import React from "react";
// internal_modules
import IconsGroupItem from "../IconsGroupItem";
// fragments
import "./styles.scss";
var IconsGroupList = function (props) {
    var length = props.data.length;
    return (React.createElement("ul", { className: "c-IconsGroup__list" }, props.data.map(function (item, index) {
        return (React.createElement(IconsGroupItem, { data: item, key: item.id + "-" + index, arrowsOfProcess: index === length - 1 ? false : props.arrowsOfProcess }));
    })));
};
export default IconsGroupList;
