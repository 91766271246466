// node_modules
import React from "react";
import { Link } from "react-router-dom";
import { Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";

// Shared components
// ...

// Partials of component
import "./styles.scss";

const FlagCorner = (props) => {
  const { fields } = props;
  const link = getFieldValue(fields, "link");
  const image = getFieldValue(fields, "image");
  const fieldNoFollow = getFieldValue(fields, "noFollow");

  if(fieldNoFollow && link.linktype === "external")
    return (
      <div className="c-FlagCorner">
        {link && link.href !== "" ? (
          <Link to={link.href} className="reset" rel="nofollow">
            <ChildImage data={image} />
            <span className="sr-only">{link.text}</span>
          </Link>
        ) : (
          <ChildImage data={image} />
        )}
      </div>
    );
  return (
    <div className="c-FlagCorner">
      {link && link.href !== "" ? (
        <Link to={link.href} className="reset">
          <ChildImage data={image} />
          <span className="sr-only">{link.text}</span>
        </Link>
      ) : (
        <ChildImage data={image} />
      )}
    </div>
  );
};

function ChildImage(props) {
  return (
    props.data && (
      <span aria-hidden="true" className="c-FlagCorner__image">
        <Image media={props.data} />
      </span>
    )
  );
}

export default FlagCorner;
