var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import IconsTextsList from "./IconsTextsList";
import "./styles.scss";
var IconsTexts = function (props) {
    var fields = props.fields;
    var divParentStyle = {
        backgroundImage: "url(" + fields.bgimgurl.value.src + ")",
    };
    var divParentClasses = "c-IconsTexts";
    var $component = {
        elementClasses: divParentClasses,
        elementStyle: divParentStyle,
    };
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, componentSettings: $component }, props),
        fields && fields.heading && (React.createElement(ComponentHeading, { tag: fields.headingTag, field: fields.heading, headingIsHidden: fields.headingIsHidden })),
        fields.iconTextList && fields.iconTextList.length > 0 && (React.createElement(IconsTextsList, __assign({ data: fields.iconTextList }, props))),
        fields.descriptionBottom && (React.createElement(RichText, { field: fields.descriptionBottom, tag: "div", className: "c-IconsTexts__descriptionBottom" }))));
};
export default IconsTexts;
