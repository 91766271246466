var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import Swiper from "react-id-swiper";
// Shared components
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// Partials of component
import "../../assets/styles/components/Swiper.scss";
import "./styles.scss";
import CarouselProjectsTile from "./CarouselProjectsTile";
SwiperCore.use([Navigation, Pagination, A11y]);
var CarouselProjects = function (props) {
    if (!props.fields) {
        return null;
    }
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, tiles = _a.tiles, _background = _a.bgColorGrey;
    var swiperParams = {
        breakpoints: {
            1201: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 100,
            },
        },
        freeMode: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        paginationClickable: true,
        spaceBetween: 0,
        slideActiveClass: "c-CarouselProjectsTile--active",
        containerClass: "c-CarouselProjects__tilesList",
        slideNextClass: "c-CarouselProjectsTile--next",
        slideClass: "c-CarouselProjectsTile",
        WrapperEl: "ul",
    };
    return (React.createElement(PlaceholderComponent, __assign({}, props, { customSpaces: true, withBg: _background }),
        React.createElement("div", { className: "c-CarouselProjects" },
            heading && (React.createElement(ComponentHeading, { className: "align-center", tag: headingTag, field: heading })),
            props.fields && tiles && tiles.length > 0 && (React.createElement(Swiper, __assign({}, swiperParams), tiles.map(function (item, index) {
                return (React.createElement(CarouselProjectsTile, __assign({}, item, { key: item.id + "-" + index })));
            }))))));
};
export default CarouselProjects;
