var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { Image, getFieldValue, Link, RichText } from "@sitecore-jss/sitecore-jss-react";
// local_modules
import { themeClassName } from "../../../helpers/app/jss/fields/Theme";
import { generalLinkIsEmpty } from "../../../helpers/app/jss/fieldTypes/GeneralLink.js";
import { cleanupEmptyItemsFromArray } from "../../../helpers/app/utils/array/index";
// fragments
import "./styles.scss";
function TileItem(props) {
    var tileImageThumb = props.fields.tileImageThumb;
    var tileTitle = getFieldValue(props.fields, "tileTitle");
    var tileExcerpt = getFieldValue(props.fields, "tileExcerpt");
    var classItemInner = ["c-ImageTextTiles__itemInner"];
    var themeClassColorText = themeClassName(props.fields);
    classItemInner.push(themeClassColorText);
    return (React.createElement(React.Fragment, null,
        tileImageThumb && (React.createElement("div", { className: "c-ImageTextTiles__itemImage" },
            React.createElement(Image, { loading: "lazy", field: tileImageThumb }))),
        React.createElement("div", { className: cleanupEmptyItemsFromArray(classItemInner) },
            tileTitle && (React.createElement("span", { className: "c-ImageTextTiles__itemTitle" }, tileTitle)),
            tileExcerpt && (React.createElement(RichText, { tag: "span", className: "c-ImageTextTiles__itemText", field: props.fields.tileExcerpt })))));
}
var Tile = function (props) {
    var tileLink = getFieldValue(props.fields, "tileLink");
    return (React.createElement("li", { className: "c-ImageTextTiles__item" }, !generalLinkIsEmpty(tileLink) ? (React.createElement(Link, { field: tileLink },
        React.createElement(TileItem, __assign({}, props)))) : (React.createElement("div", null,
        React.createElement(TileItem, __assign({}, props))))));
};
export default Tile;
