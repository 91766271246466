var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import "./styles.scss";
var ImageText = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, contentImage = _a.contentImage, hash = _a.hash, ulList = _a.ulList;
    return (React.createElement(PlaceholderComponent, __assign({}, props, { withBg: true, withoutSpaces: true, withHashNavigationNavigation: hash && hash.value }),
        React.createElement("div", { className: "c-ImageText" },
            React.createElement("div", { className: "c-ImageText__heading" }, heading && React.createElement(ComponentHeading, { tag: headingTag, field: heading })),
            React.createElement("div", { className: "c-ImageText__cover" }, contentImage && (React.createElement(Image, { loading: "lazy", className: "c-ImageText__cover__image", media: contentImage }))),
            React.createElement("div", { className: "c-ImageText__list" },
                React.createElement("ul", null, ulList &&
                    ulList.map(function (item, index) { return (React.createElement("li", { key: "c-ImageText-ps-" + index },
                        React.createElement(RichText, { field: item.fields.liField }))); }))))));
};
export default ImageText;
