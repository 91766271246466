var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { RichText, getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import { themeClassName } from "../../helpers/app/jss/fields/Theme";
import { bgGradientFromConfiguration } from "../../helpers/app/jss/fields/Gradient";
// TODO: replace to import {themeClassName} from '../../helpers/app/utils/fields/Theme'; after implement theme configuration
// Partials of component
import IconsGroupList from "./IconsGroupList";
import "./styles.scss";
var IconsGroup = function (props) {
    var fields = props.fields;
    var heading = fields.heading, headingTag = fields.headingTag, description = fields.description, bgColorGradientStep = fields.bgColorGradientStep, iconTexts = fields.iconTexts;
    var _variantSizeElements = getFieldValue(fields, "smallElements")
        ? " c-IconsGroup--small"
        : " c-IconsGroup--normal";
    var arrowsOfProcess = getFieldValue(fields, "arrowsOfProcess");
    var _variantArrowsOfProcess = arrowsOfProcess
        ? " c-IconsGroup--arrows"
        : "";
    var variantType = "";
    if (props.fields && props.fields.variantType) {
        var varinatClassValue = getFieldValue(props.fields.variantType, "classname");
        if (varinatClassValue) {
            variantType = "" + varinatClassValue;
        }
    }
    // Get value (class gradient step) from 'bgColorGradientStep' field
    var _bgColorGradientStepFields = bgColorGradientStep
        ? bgColorGradientStep
        : null;
    var _bgGradientClassName = "";
    if (_bgColorGradientStepFields) {
        var _gradientFieldValue = bgGradientFromConfiguration(_bgColorGradientStepFields);
        _bgGradientClassName = _gradientFieldValue.value; // gradient class
    }
    // Get class for color texts from 'theme' field
    var _themeClassName = themeClassName(fields);
    var divParentClasses = "c-IconsGroup" + _themeClassName + _variantSizeElements + _variantArrowsOfProcess + " align-center";
    // Style inline
    var placeholderComponentStyle = null;
    // Style inline "background-image"
    var bgimgurl = getFieldValue(props.fields, "bgimgurl");
    if (bgimgurl && bgimgurl.src) {
        placeholderComponentStyle = {
            backgroundImage: "url(" + bgimgurl.src + ")",
        };
    }
    var $component = {
        backgroundCover: !!placeholderComponentStyle,
        backgroundClass: _bgGradientClassName,
        elementClasses: divParentClasses,
        elementStyle: placeholderComponentStyle,
    };
    var withBg = !!(placeholderComponentStyle || _bgGradientClassName !== "");
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, withBg: withBg, componentSettings: $component }, props),
        React.createElement(ComponentHeading, { tag: headingTag, field: heading }),
        variantType === "variant1" && (React.createElement("div", null,
            description && (React.createElement(RichText, { field: description, tag: "div", className: "c-IconsGroup__description" })),
            iconTexts && iconTexts.length > 0 && (React.createElement(IconsGroupList, { data: iconTexts, arrowsOfProcess: arrowsOfProcess })))),
        variantType === "variant2" && (React.createElement("div", null,
            iconTexts && iconTexts.length > 0 && (React.createElement(IconsGroupList, { data: iconTexts, arrowsOfProcess: arrowsOfProcess })),
            description && (React.createElement(RichText, { field: description, tag: "div", className: "c-IconsGroup__description" }))))));
};
export default IconsGroup;
