import React from "react";
import { NavLink } from "react-router-dom";
import { getFieldValue, Text, Image } from "@sitecore-jss/sitecore-jss-react";
import BtnElevator from "../../../shared/BtnElevator";
import { bgGradientFromConfiguration } from "../../../helpers/app/jss/fields/Gradient";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

import "./styles.scss";

const OfferTile = (props) => {
  // console.log('OfferTile', props)
  const { fields } = props;
  const _offerTileFullBgImage = getFieldValue(fields, "offerTileFullBgImage");
  const _offerTileBackgroundGradient =
    fields.offerTileBackgroundGradient &&
    fields.offerTileBackgroundGradient.fields
      ? bgGradientFromConfiguration(fields.offerTileBackgroundGradient).value
      : null;
  const _offerTileBackgroundImage = getFieldValue(
    fields,
    "offerTileBackgroundImage"
  );
  const _showTileBackgroundGradient = _offerTileBackgroundGradient
    ? _offerTileBackgroundGradient
    : null;

  const _className = `c-OfferTile ${
    _offerTileBackgroundGradient
      ? _offerTileBackgroundGradient
      : "c-OfferTile--white"
  } ${_offerTileFullBgImage ? "c-OfferTile--full-bg-image" : ""}`;

  return (
    <li className={_className}>
      {_offerTileBackgroundImage && (
        <BgImageHandler {...fields.offerTileBackgroundImage} />
      )}
      <article className="c-OfferTile__innerContent">
        <TileInnerContainer {...props}>
          <InsideElement
            showBgGradient={_showTileBackgroundGradient}
            {...props}
          />
        </TileInnerContainer>
      </article>
    </li>
  );
};

const BgImageHandler = (props) => (
  <div className="c-OfferTile__image">
    <Image loading="lazy" field={props} />
  </div>
);

const HeaderImageHandler = (props) => (
  <div className="c-OfferTile__header_image">
    <Image loading="lazy" field={props} />
  </div>
);

const TileInnerContainer = (props) => {
  const { fields, showButton } = props;
  const _offerTileLink = getFieldValue(fields, "offerTileLink");
  const _blockLink = getFieldValue(fields, "blockLink");

  if (_offerTileLink && !showButton) {
    let _link = {
      href: _offerTileLink.href,
      text: _offerTileLink.text,
    };
    console.log(_blockLink);
    if(_blockLink)
      return props.children; 
    return <NavLink to={_link.href} children={props.children} />;
  }
  return props.children;
};

const InsideElement = (props) => {
  const { fields, showBgGradient, showButton, tag } = props;

  const _offerTileLink = getFieldValue(fields, "offerTileLink");
  const _offerTileTitle = getFieldValue(fields, "offerTileTitle");
  const _offerTileDescription = getFieldValue(fields, "offerTileDescription");
  const _offerTileHeaderImage = getFieldValue(fields, "offerTileHeaderImage")
  const _offerCategory = _offerTileHeaderImage && _offerTileHeaderImage.src ? null : // Disable category if header image exists
    fields.offerTileCategory && fields.offerTileCategory.fields
      ? fields.offerTileCategory.fields
      : null;
  
  const _offerTileCategoryTitle = offerTileCategoryTitle(_offerCategory);

  const _offerTileCategoryColor = offerTileCategoryColor(_offerCategory);
  const _offerTileCategoryColorClassName =
    showBgGradient === null &&
    _offerTileCategoryColor &&
    _offerTileCategoryColor.fields
      ? getFieldValue(_offerTileCategoryColor.fields, "textColor")
      : "";
  const _offerTileCategoryClassName = `c-OfferTile__category ${_offerTileCategoryColorClassName}`;
  const fieldNoFollow = getFieldValue(fields, "noFollow");

  return (
    <React.Fragment>
      <header>
        {_offerCategory && (
          <Text
            tag="p"
            className={_offerTileCategoryClassName}
            field={_offerTileCategoryTitle}
          />
        )}

        {_offerTileHeaderImage && (
          <HeaderImageHandler {...fields.offerTileHeaderImage} />
        )}

        {/* FIXME Experience editor compatibility */}
        {tag && _offerTileTitle && (
          <Text
            tag={tag}
            field={fields.offerTileTitle}
            className="c-OfferTile__title"
          />
        )}

        {!tag && _offerTileTitle && (
          <RichText
            className="c-OfferTile__title"
            field={fields.offerTileTitle}
          />
        )}
      </header>
      {_offerTileDescription && (
        <RichText
          className="c-OfferTile__text"
          field={fields.offerTileDescription}
        />
      )}
      {showButton && _offerTileLink && (
        <div className="c-OfferTile__button">
          <BtnElevator
            field={_offerTileLink}
            theme={showBgGradient ? "light" : "dark"}
            noFollow = {fieldNoFollow}
          />
        </div>
      )}
    </React.Fragment>
  );
};

function offerTileCategoryTitle(offerTileCategory) {
  return offerTileCategory && offerTileCategory.title
    ? offerTileCategory.title
    : null;
}

function offerTileCategoryColor(offerTileCategory) {
  return offerTileCategory && offerTileCategory.titleColor
    ? offerTileCategory.titleColor
    : null;
}

export default OfferTile;
