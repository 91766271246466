var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { getFieldValue, } from "@sitecore-jss/sitecore-jss";
// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// internal_modules
import BrandsList from "./BrandsList";
// fragments
import "./styles.scss";
var Brands = function (props) {
    var _a = props.fields, heading = _a.heading, description = _a.description, brands = _a.brands, headingTag = _a.headingTag, headingIsHidden = _a.headingIsHidden;
    // Style inline
    var placeholderComponentStyle = null;
    // Style inline "background-image"
    var bgimgurl = getFieldValue(props.fields, "bgimgurl");
    if (bgimgurl && bgimgurl.src) {
        placeholderComponentStyle = {
            backgroundImage: "url(" + bgimgurl.src + ")",
        };
    }
    var $componentSettings = {
        backgroundCover: !!placeholderComponentStyle,
        elementClasses: "c-Brands",
        elementStyle: placeholderComponentStyle,
    };
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, customSpaces: true, componentSettings: $componentSettings }, props),
        heading && (React.createElement(ComponentHeading, { tag: headingTag, field: heading, className: "align-center", headingIsHidden: headingIsHidden })),
        description && (React.createElement(RichText, { className: "c-LogosGroup__description", tag: "p", field: description })),
        brands && React.createElement(BrandsList, { brands: brands })));
};
export default Brands;
