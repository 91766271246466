var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";
import { getFieldValue, } from "@sitecore-jss/sitecore-jss";
import Swiper from "react-id-swiper";
// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import "../../assets/styles/components/Swiper.scss";
// fragments
import "./styles.scss";
// Caution! The following constants necessary to checking variant of content data (slide variant). If you want change it, please look at sitecore definition for "ContentData-ImagesGrid-[...]"
var FIELD_NAME_IMAGE_PREFX = "image";
var FIELD_NAME_HORIZONTAL = "horizontal";
var FIELD_NAME_VERTICAL = "vertical";
var ImagesGrid = function (props) {
    var images = props.fields.images;
    var swiperParams = {
        // breakpoints: {
        //   768: {
        //     spaceBetween: 30,
        //   },
        // },
        slidesPerView: "auto",
        initialSlide: 1,
        spaceBetween: 12,
    };
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, withoutSpaces: false }, props),
        React.createElement("div", { className: "c-ImagesGrid" }, images && (React.createElement(Swiper, __assign({}, swiperParams), images.map(function (item, index) {
            var key = "ImagesGrid-slide-" + index;
            var _a = item.fields, image1 = _a.image1, image2 = _a.image2, image3 = _a.image3, image4 = _a.image4;
            var variantTypeNumber = variantTypeByContentData(item);
            var variant3ClassName = "";
            if (variantTypeNumber === 3) {
                if (getFieldValue(item.fields, FIELD_NAME_HORIZONTAL)) {
                    variant3ClassName =
                        "c-ImagesGrid__container--two-horizontal-boxes";
                }
                else if (getFieldValue(item.fields, FIELD_NAME_VERTICAL)) {
                    variant3ClassName =
                        "c-ImagesGrid__container--two-vertical-boxes";
                }
            }
            return (React.createElement("div", { key: key, className: "swiper-slide" },
                variantTypeNumber === 1 && (React.createElement("div", { className: "c-ImagesGrid__container c-ImagesGrid__slide--one-big" },
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image1 })))),
                variantTypeNumber === 2 && (React.createElement("div", { className: "c-ImagesGrid__container c-ImagesGrid__container--two-vertical-squares" },
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image1 })),
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image1 })))),
                variantTypeNumber === 3 && variant3ClassName && (React.createElement("div", { className: "c-ImagesGrid__container " + variant3ClassName },
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image1 })),
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image2 })))),
                variantTypeNumber === 4 && (React.createElement("div", { className: "c-ImagesGrid__container c-ImagesGrid__container--four-wide-boxes" },
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image1 })),
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image2 })),
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image3 })),
                    React.createElement("div", { className: "c-ImagesGrid__image" },
                        React.createElement(Image, { loading: "lazy", media: image4 }))))));
        }))))));
};
function variantTypeByContentData(item) {
    var variantType = 0;
    // Check amount of fields for images
    var imagesAvailable = Object.keys(item.fields).filter(function (item) {
        return item.includes(FIELD_NAME_IMAGE_PREFX);
    }).length;
    variantType = imagesAvailable;
    if (imagesAvailable === 2) {
        var variant2or3 = Object.keys(item.fields).some(function (item) {
            return (item.includes(FIELD_NAME_HORIZONTAL) ||
                item.includes(FIELD_NAME_VERTICAL));
        });
        variantType = variant2or3 ? 3 : 2;
    }
    return variantType;
}
export default ImagesGrid;
