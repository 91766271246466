import React, { useState, useEffect } from "react";
import {
  withPlaceholder,
  withSitecoreContext,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";

import "./styles.scss";
import AccordionItem from "./AccordionItem";

const Accordion = (props) => {
  const { accordionPlaceholder, sitecoreContext, fields } = props;
  let activateItem = getFieldValue(fields, "activateItem") ? 0 : -1;
  const [activeAccordionItemsIndex, setActiveAccordionItemIndex] = useState([]);

  useEffect(() => {
    if (activateItem === 0) {
      setActiveAccordionItemIndex((state) => [...state, activateItem]);
    }
  }, [activateItem]);

  const handleEnter = (event, index, expandedItemIndex) => {
    event.persist();

    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      setActiveAccordionItem(event, index, expandedItemIndex);
    }
  };

  const setActiveAccordionItem = (event, index, expandedItemIndex) => {
    event.persist();

    if (!expandedItemIndex) {
      setActiveAccordionItemIndex((state) => [...state, index]);
    } else {
      const updatedActiveAccordionItemsIndex = activeAccordionItemsIndex.filter(
        (collapsedIndex) => collapsedIndex !== index
      );
      setActiveAccordionItemIndex(updatedActiveAccordionItemsIndex);
    }

    // FIXME: fix scroll to heading of accordion
    // let $header = event.target;
    // if ($header.classList.contains('c-Accordion__title')) {
    //   $header.scrollIntoView();
    // } else {
    //   $header = $header.closest('.c-Accordion__title');
    //   $header.scrollIntoView();
    // }
  };
//   let validTabIndex = 0;
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const heading = getFieldValue(fields, "heading");
  const description = getFieldValue(fields, "description");

  // console.log("accordin-- ", props, sitecoreContext.pageEditing);

  return (
    <PlaceholderComponent withContainer={false} {...props}>
      <div className="c-Accordion">
        <div className="container">
          <div className="container__inner">
            {heading && (
              <ComponentHeading
                className="align-center"
                tag={fields.headingTag}
                field={fields.heading}
                headingIsHidden={fields.headingIsHidden}
              />
            )}
            {description && (
              <RichText
                className="align-center"
                tag="div"
                field={fields.description}
              />
            )}
          </div>
        </div>
        {/*
            When the page is editing, we hide the tab headings, because they are not updated when xEditor adds or removes a tab rendering.
            Instead, we show the tab header inline with the tab contents (see Styleguide-Layout-Tabs-Tab).
          */}
        {/* {!isEditing &&
            (accordionPlaceholder || [])
              .filter((item) => item.props && item.props.fields)
              .map((item, index) => (
                <li className="nav-item" key={`tab${index}`}>
                  <a
                    className={`nav-link ${index === activeTabIndex ? 'active' : null}`}
                    onClick={() => setActiveTab(index)}
                    href="#t"
                  >
                    <Text field={item.props.fields.title} />
                  </a>
                </li>
              ))} */}

        <div className="c-Accordion__list" role="tablist" aria-label={heading}>
          {(accordionPlaceholder || []).map((item, index) => {
            const expandedItemIndex = activeAccordionItemsIndex.some(
              (expandedItem) => expandedItem === index
            );
            const isValid = item.props && item.props.fields;
            // console.log("tile--", item, "isEditing", isEditing, "isExperienceEditorActive", isExperienceEditorActive());

            // allow experience editor markup components to render
            if (!isValid && isEditing) return item;
            // validTabIndex += 1;

            // FIXME:
            // we render the item either if it's active - or we're editing,
            // in which case we stack all tabs for visual editing
            // if (activeTabIndex === validTabIndex - 1 || (isEditing && isExperienceEditorActive())) {
            //   return item;
            // }

            return (
              //item.props.fields &&
              <AccordionItem
                key={`tab${index}`}
                index={index}
                fields={item.props.fields}
                isActive={expandedItemIndex}
                onTitleClick={(event) =>
                  setActiveAccordionItem(event, index, expandedItemIndex)
                }
                handleEnter={(event) =>
                  handleEnter(event, index, expandedItemIndex)
                }
              />
            );
          })}
        </div>
      </div>
    </PlaceholderComponent>
  );
};

// This is a _higher order component_ that will wrap our component and inject placeholder
// data into it as a prop (in this case, props.accordionPlaceholder).
// this another option compared to using the <Placeholder> component;
// in this case, chosen because we primarily need access to the _data_
// of the placeholder.
const AccordionComponentWithPlaceholderInjected = withPlaceholder({
  placeholder: "jss-accordion",
  prop: "accordionPlaceholder",
})(Accordion);

// We need to know if experience editor is active, to disable the dynamic item behavior for editing.
// Using the same technique as injecting the placeholder, we wrap the component again to inject the
// `sitecoreContext` prop.
const AccordionWithPlaceholderAndSitecoreContext = withSitecoreContext()(
  AccordionComponentWithPlaceholderInjected
);

export default AccordionWithPlaceholderAndSitecoreContext;
