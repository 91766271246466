import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import OfferTile from "./OfferTile";
import { headingTagField } from "../../shared/Component/ComponentHeading";

import "./styles.scss";

const OffersTiles = (props) => {
  const { fields } = props;
  const heading = getFieldValue(fields, "heading");
  const offersTilesHeadingTag = headingTagField(fields.offersTilesHeadingTag);
  const offersType = getOffersType(fields);
  const showButton = (function () {
    switch (offersType) {
      case "services":
        return false;
      case "jobs":
        return true;
      default:
        return false;
    }
  })();

  return (
    <PlaceholderComponent {...props} customSpaces={true}>
      <section className="c-OffersTiles">
        {heading && (
          <ComponentHeading tag={fields.headingTag} field={fields.heading} />
        )}
        {fields && fields.offersTiles && (
          <div className="c-OffersTiles__list">
            <ul className="reset">
              {fields.offersTiles.map((item, index) => {
                return (
                  <OfferTile
                    showButton={showButton}
                    {...item}
                    key={`${item.id}-${index}`}
                    tag={offersTilesHeadingTag}
                  />
                );
              })}
            </ul>
          </div>
        )}
      </section>
    </PlaceholderComponent>
  );
};

function getOffersType(fields) {
  if (Array.isArray(fields.offersType)) {
    let offerTypeField =
      fields.offersType && fields.offersType.length > 0
        ? fields.offersType[0]
        : null;
    if (offerTypeField) {
      offerTypeField = offerTypeField.fields ? offerTypeField.fields : null;
      return getFieldValue(offerTypeField, "value");
    } else {
      return null;
    }
  } else {
    const offerTypeField = fields.offersType ? fields.offersType : null;
    return offerTypeField ? getFieldValue(offerTypeField, "value") : null;
  }
}

export default OffersTiles;
