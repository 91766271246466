var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import "./styles.scss";
var DescriptionSection = function (props) {
    var fields = props.fields;
    var heading = fields.heading, headingTag = fields.headingTag, description = fields.description;
    var $component = {
        wrapperClasses: "component--hr",
    };
    return (React.createElement(PlaceholderComponent, __assign({ withoutSpaces: true, componentSettings: $component }, props),
        React.createElement("div", { className: "c-DescriptionSection" },
            React.createElement("div", { className: "c-DescriptionSection__left" }, heading && (React.createElement(ComponentHeading, { className: "align-center", tag: headingTag, field: heading }))),
            React.createElement("div", { className: "c-DescriptionSection__right" }, description && React.createElement(RichText, { field: description, tag: "p" })))));
};
export default DescriptionSection;
