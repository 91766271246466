var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React, { useContext } from "react";
import FormContact from "../FormContact";
import ContactVisibility from '../../store/ContactVisibility/ContactVisibility';
import "./styles.scss";
var ContactPopup = function (props) {
    var popupIsVisible = useContext(ContactVisibility);
    return (React.createElement(React.Fragment, null, popupIsVisible.contactPopup.contactIsVisible &&
        React.createElement("div", { className: "c-ContactPopup" },
            React.createElement("div", { className: "c-ContactPopup__blackdrop", onClick: popupIsVisible.contactPopup.onHidePopup }),
            React.createElement("div", { className: "c-ContactPopup__content" },
                React.createElement("div", { className: "c-ContactPopup__wrapper" },
                    React.createElement("button", { className: "c-ContactPopup__close", onClick: popupIsVisible.contactPopup.onHidePopup }, "\u00D7"),
                    React.createElement(FormContact, __assign({}, props)))))));
};
export default ContactPopup;
