var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { Image, Text, Link } from "@sitecore-jss/sitecore-jss-react";
// fragments
import "./styles.scss";
function Person(props) {
    var _a = props.fields, personName = _a.personName, galleryImage = _a.galleryImage, companyPosition = _a.companyPosition;
    return (React.createElement("figure", { className: "c-People" },
        React.createElement("div", { className: "c-People__image" },
            React.createElement(Image, { loading: "lazy", media: galleryImage })),
        React.createElement("figcaption", { className: "c-People__innerContent" },
            React.createElement("p", { className: "c-People__name" },
                React.createElement(Text, { field: personName })),
            React.createElement("p", { className: "c-People__position" },
                React.createElement(Text, { field: companyPosition.fields.positionName })))));
}
var PeopleItem = function (props) {
    var personDescriptionPage = props.fields.personDescriptionPage;
    return personDescriptionPage && props.currentLanguage ? (React.createElement(Link, { field: {
            value: {
                href: "/" + props.currentLanguage + personDescriptionPage.url,
            },
        }, className: "c-People__link reset" },
        React.createElement(Person, __assign({}, props)))) : (React.createElement(Person, __assign({}, props)));
};
export default PeopleItem;
