import React from "react";
import { Link as LinkReact } from "react-router-dom";
import { HashLink as LinkWithHash } from "react-router-hash-link";
import { Link as LinkSitecore } from "@sitecore-jss/sitecore-jss-react";

import "./styles.scss";
import { isSafari } from "../../helpers/app/utils/browsers-dectection";
import { hrefValue } from "../../helpers/app/jss/fieldTypes/GeneralLink";

// const scrollWithOffset = (el) => {
//   if (typeof window !== "undefined") {
//     const marginTop = parseInt(getComputedStyle(el).marginTop);
//     let top = window.scrollY + el.getBoundingClientRect().top;
//     let scrollPosition = top - marginTop;
//     window.scroll({
//       top: scrollPosition,
//       left: 0,
//       behavior: "smooth",
//     });
//   } else {
//     el.scrollIntoView({ behavior: "smooth", block: "start" });
//   }
// };

const BtnText = (text) => (
  <>
    {text}
    <span>
      <span datatext={text}></span>
    </span>
  </>
);

export default function BtnElevator(props) {
  const noFollow = props.noFollow
  const _theme = props.theme || "dark";
  const text = props.field && props.field.text ? props.field.text : "";
  let href = props.field && props.field.href ? props.field.href : "";
  const linktype =
    props.field && props.field.linktype ? props.field.linktype : "";

  const btnSafari = isSafari() ? "btn-elevator-safari" : "";
  const classes = btnSafari + " btn-elevator btn-elevator--" + _theme; // safari fix

  let mailto = false;
  if (linktype !== "mailto") {
    href = hrefValue(href);
  } else {
    mailto = true;
  }

  switch (props.field.linktype) {
    case "external":
    case "mailto":
    case "javascript":
      if(noFollow)
        return (
          <LinkSitecore
            field={props.field}
            children={BtnText(text)}
            className={classes}
            rel = "nofollow noopener"
          />
        )
        return (
            <LinkSitecore
              field={props.field}
              children={BtnText(text)}
              className={classes}
            />   
        );
    default:
      return !href.hasHash ? (
        !mailto ? (
          <LinkReact className={classes} children={BtnText(text)} to={href} />
        ) : (
          <a href={href} title={text} className={classes}>
            {BtnText(text)}
          </a>
        )
      ) : (
        <LinkWithHash
          className={classes}
          smooth
          to={href.value}
          // scroll={el => scrollWithOffset(el)}
          children={BtnText(text)}
        />
      );
  }
}
