export function generalLinkIsEmpty(data) {
  // Checking lengths greater than 1 because by default Sitecore renders one property even if the link is empty.
  // property "href" is built-in property in Sitecore data and always is rendered even that is empty
  // using with method getFieldValue() from jss-sitecore
  const propsData = data && Object.keys(data);
  if (propsData && propsData.length > 0) {
    if (propsData.includes("href") && data["href"].length === 0) {
      return true;
    }
    return false;
  }
  return false;
}

export function hrefValue(href) {
  href = getValueWithHash(href);

  if (href.includes("#")) {
    return {
      hasHash: true,
      value: href,
    };
  }
  return href;
}

export function getValueWithHash(href) {
  if (href.includes("/#")) {
    return href.slice(1);
  }
  return href;
}

// TODO: needs refactor
// export function CustomLink(props) {
//   switch (props.data.linktype) {
//     case "external":
//     case "mailto":
//     case "javascript":
//       return <Link field={props.data} {...props} />;
//     default:
//       return (
//         <NavLink
//           activeClassName={"is-active"}
//           to={props.data.href}
//           className="c-Navigation__anchor"
//         >
//           <Text field={props.itemTitle || { value: props.data.text }} />
//         </NavLink>
//       );
//   }
// }
