import React from 'react';

export default function IconArrowProcess(props){
    return (
        <svg {...props} className={props.className} viewBox="0 0 595.281 523.445" enableBackground="new 0 0 595.281 523.445"><path fillRule="evenodd" d="M515.806,309.435l0.015,0.001c0.018-0.006,0.037-0.01,0.058-0.02c1.44-0.173,2.727-0.665,3.915-1.296
		c0.37-0.187,0.666-0.403,0.99-0.625c0.802-0.558,1.503-1.217,2.094-2c0.227-0.281,0.479-0.521,0.665-0.839
		c0.103-0.158,0.249-0.27,0.335-0.432c0.526-1,0.855-2.083,1-3.208c0.026-0.11-0.021-0.241-0.005-0.361
		c0.124-1.257-0.032-2.543-0.354-3.839c-0.05-0.181-0.002-0.353-0.051-0.533l-25.874-84.531
		c-1.979-6.473-9.058-11.095-15.829-10.323c-6.755,0.78-10.625,6.658-8.643,13.143l13.939,45.574
		c-41.943-32.826-90.209-54.097-92.496-55.087C248.621,136.176,94.855,165.35,52.784,270.081
		c-2.081,5.188,0.598,11.536,6.045,15.017c0.585,0.373,1.194,0.714,1.832,1.018c6.658,3.115,13.914,1.006,16.221-4.726
		c37.477-93.289,176.691-118.22,310.733-55.395c0.623,0.265,50.498,22.223,90.129,54.376l-60.127-9.906
		c-7.046-1.153-12.824,3.115-12.914,9.533c-0.108,6.42,5.531,12.539,12.578,13.731l94.773,15.611
		C513.344,309.545,514.6,309.569,515.806,309.435z"/></svg>
    );
}