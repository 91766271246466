/* eslint-disable import/first */
// node_modules
import React, { useState, useContext } from "react";
import { RichText, Image, getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
import ContactVisibility from '../../store/ContactVisibility/ContactVisibility';
// local_modules
import iconPhone from "../../assets/img/icon-touchcta-phone.svg";
import iconMessage from "../../assets/img/icon-touchcta-message.svg";
// fragments
import "./styles.scss";
var TouchCTA = function (props) {
    var _a = props.fields, textTop = _a.textTop, textBottom = _a.textBottom, imgurl = _a.imgurl;
    var phone = getFieldValue(props.fields, "phoneto");
    var phoneto = "tel:" + phone;
    var _b = useState(true), touchCTAIsVisible = _b[0], setTouchCTAIsVisible = _b[1];
    var closeTouchCTA = function () {
        setTouchCTAIsVisible(false);
    };
    function pushPhoneDatalayer() {
        window.dataLayer.push({
            'event': 'click_phone',
            'page_path': window.location.pathname,
            'click_text': phone
        });
    }
    var popupIsVisible = useContext(ContactVisibility);
    return (React.createElement(React.Fragment, null, touchCTAIsVisible &&
        React.createElement("div", { className: "c-TouchCTA" },
            React.createElement("button", { onClick: closeTouchCTA, className: "c-TouchCTA__close", id: "TouchCTA-close" }, "\u00D7"),
            React.createElement("div", { className: "c-TouchCTA__icon-main" }, imgurl &&
                React.createElement("button", { className: "c-TouchCTA__contactButton", onClick: popupIsVisible.contactPopup.onShowPopup },
                    React.createElement(Image, { className: "c-TouchCTA__", media: imgurl, id: "TouchCTA-person" }))),
            React.createElement("div", { className: "c-TouchCTA__icon-wrapper" },
                React.createElement("a", { href: phoneto },
                    React.createElement("div", { className: "c-TouchCTA__icon c-TouchCTA__icon-phone", onClick: pushPhoneDatalayer },
                        React.createElement("img", { src: iconPhone, alt: "quotation mark", "aria-hidden": "true", id: "TouchCTA-phone" }))),
                React.createElement("button", { className: "c-TouchCTA__icon c-TouchCTA__icon-message", onClick: popupIsVisible.contactPopup.onShowPopup },
                    React.createElement("img", { src: iconMessage, alt: "quotation mark", "aria-hidden": "true", id: "TouchCTA-mail" }))),
            React.createElement("button", { className: "c-TouchCTA__contactButton", onClick: popupIsVisible.contactPopup.onShowPopup },
                textTop &&
                    React.createElement(RichText, { className: "c-TouchCTA__text-top", field: textTop, tag: "div", id: "TouchCTA-text-Lets-get-in" }),
                textBottom &&
                    React.createElement(RichText, { className: "c-TouchCTA__text-bottom", field: textBottom, tag: "div", id: "TouchCTA-text-Touch" })))));
};
export default TouchCTA;
