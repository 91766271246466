/* eslint-disable import/first */
import React from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import "./styles.scss";
var ExpertTile = function (props) {
    var _a = props.fields, personName = _a.personName, bio = _a.bio, galleryImage = _a.galleryImage, companyPosition = _a.companyPosition;
    var positionName = companyPosition.fields.positionName;
    var step = props.step;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "c-ExpertTile" },
            React.createElement("div", { className: "c-ExpertTile__wrapper" },
                React.createElement("div", { className: "c-ExpertTile__image" },
                    React.createElement(Image, { loading: "lazy", field: galleryImage })),
                React.createElement("div", { className: "c-ExpertTile__description" },
                    React.createElement("div", { className: "c-ExpertTile__top" },
                        React.createElement(RichText, { className: "c-ExpertTile__description__title", tag: "p", field: personName }),
                        React.createElement(RichText, { className: "c-ExpertTile__description__subtitle clip-text--gradient-step" + step.value, tag: "p", field: positionName })),
                    React.createElement(RichText, { className: "c-ExpertTile__description__text", tag: "p", field: bio }))))));
};
export default ExpertTile;
