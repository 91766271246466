import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
export function metaTags(data) {
    var pageOgImageValue = getFieldValue(data, "pageOgImage");
    var pageTwitterImageValue = getFieldValue(data, "pageTwitterImage");
    var _pageOgUrl = "";
    if (typeof window !== "undefined") {
        _pageOgUrl = window.location.origin + window.location.pathname;
    }
    var _pageTwitterSite = "@InfinityGroupSV";
    var pageDescription = {
        name: "description",
        content: data.pageDescription && data.pageDescription.value
            ? data.pageDescription.value
            : "Infinity Group – we deliver advanced marketing and technology services. We increase sales, we transform assumptions into real success - for more than 15 years.",
    };
    var pageKeywords = {
        name: "keywords",
        content: data.pageKeywords && data.pageKeywords.value
            ? data.pageKeywords.value
            : "",
    };
    /**
     * OG (Facebook) Meta Tags
     */
    var pageOgTitle = {
        property: "og:title",
        content: data.pageOgTitle && data.pageOgTitle.value
            ? data.pageOgTitle.value
            : "Infinity Group - ready to deliver",
    };
    var pageOgUrl = {
        property: "og:url",
        content: _pageOgUrl,
    };
    var pageOgDescription = {
        property: "og:description",
        content: data.pageOgDescription && data.pageOgDescription.value
            ? data.pageOgDescription.value
            : "Infinity Group – we deliver advanced marketing and technology services. We increase sales, we transform assumptions into real success - for more than 15 years.",
    };
    var pageOgType = {
        property: "og:type",
        content: data.pageOgType && data.pageOgType.value
            ? data.pageOgType.value
            : "website",
    };
    var pageOgImage = {
        property: "og:image",
        content: pageOgImageValue && pageOgImageValue.src
            ? pageOgImageValue.src
            : "https://www.infinity-group.pl/dist/sig/static/media/infinity-group.dbfe3cc3.svg",
    };
    /**
     * Twitter Meta Tags
     */
    var pageTwitterCard = {
        name: "twitter:card",
        content: data.pageTwitterCard && data.pageTwitterCard.value
            ? data.pageTwitterCard.value
            : "summary",
    };
    var pageTwitterSite = {
        name: "twitter:site",
        content: _pageTwitterSite,
    };
    var pageTwitterTitle = {
        name: "twitter:title",
        content: data.pageTwitterTitle && data.pageTwitterTitle.value
            ? data.pageTwitterTitle.value
            : "Infinity Group - ready to deliver",
    };
    var pageTwitterDescription = {
        name: 'twitter:description"',
        content: data.pageTwitterDescription && data.pageTwitterDescription.value
            ? data.pageTwitterDescription.value
            : "We are leading, certified Sitecore Solution Partner with extensive experience in implementing complex projects worldwide.",
    };
    var pageTwitterImage = {
        name: "twitter:image",
        content: pageTwitterImageValue && pageTwitterImageValue.src
            ? pageTwitterImageValue.src
            : "https://www.infinity-group.pl/dist/sig/static/media/infinity-group.dbfe3cc3.svg",
    };
    return [
        pageDescription,
        pageKeywords,
        pageOgTitle,
        pageOgUrl,
        pageOgType,
        pageOgDescription,
        pageOgImage,
        pageTwitterCard,
        pageTwitterSite,
        pageTwitterTitle,
        pageTwitterDescription,
        pageTwitterImage,
    ];
}
