/* eslint-disable import/first */
import React from "react";
import { Text, Image, RichText } from "@sitecore-jss/sitecore-jss-react";
import { headingTagField } from "../../../shared/Component/ComponentHeading";
import "./styles.scss";
import imageHeading from "../../../assets/img/image-heading.png";
var CarouselProjectsTile = function (props) {
    if (!props.fields) {
        return null;
    }
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, projectScreen = _a.projectScreen, awardIcon = _a.awardIcon, awardClient = _a.awardClient, awardCategory = _a.awardCategory, awardCategoryHeader = _a.awardCategoryHeader, awardClientHeader = _a.awardClientHeader;
    return (React.createElement("li", { className: "c-CarouselProjectsTile swiper-slide" },
        React.createElement("div", { className: "c-CarouselProjectsTile__swipercontainer" },
            React.createElement("div", { className: "c-CarouselProjectsTile__imageframe" },
                React.createElement("img", { src: imageHeading, alt: "image heading", "aria-hidden": "true" }),
                React.createElement(Image, { loading: "lazy", field: projectScreen })),
            React.createElement("div", { className: "c-CarouselProjectsTile__description" },
                React.createElement(Image, { loading: "lazy", field: awardIcon, className: "c-CarouselProjectsTile__icon" }),
                React.createElement("div", { className: "c-CarouselProjectsTile__text" },
                    React.createElement("div", { className: "c-CarouselProjectsTile__text__title" },
                        React.createElement("strong", null,
                            React.createElement(RichText, { tag: headingTagField(headingTag) || "h3", field: heading }))),
                    React.createElement("p", { className: "c-CarouselProjectsTile__text__small" },
                        React.createElement("span", null, awardCategoryHeader),
                        React.createElement("span", null,
                            React.createElement("strong", null,
                                React.createElement(Text, { field: awardCategory, tag: "span" })))),
                    React.createElement("p", { className: "c-CarouselProjectsTile__text__small" },
                        React.createElement("span", null, awardClientHeader),
                        React.createElement("span", null,
                            React.createElement("strong", null,
                                React.createElement(Text, { field: awardClient, tag: "span" })))))))));
};
export default CarouselProjectsTile;
