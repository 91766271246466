/* eslint-disable import/first */
// node_modules
import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
var ImageTextSliderItem = function (props) {
    var title = props.title, description = props.description, className = props.className;
    return (React.createElement("div", { className: "c-ImageTextSlider__textWrapper " + className + " " },
        title && (React.createElement(RichText, { field: title, className: "c-ImageTextSlider__textTitle" })),
        description && (React.createElement(RichText, { field: description, className: "c-ImageTextSlider__textDescription" }))));
};
export default ImageTextSliderItem;
