var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import "./styles.scss";
var FeaturedText = function (props) {
    var _a = props.fields, text = _a.text, imgurl = _a.imgurl;
    return (React.createElement(PlaceholderComponent, __assign({}, props, { withoutSpaces: true }),
        React.createElement("div", { className: "c-FeaturedText" },
            React.createElement("div", { className: "c-FeaturedText__image" }, imgurl && (React.createElement(Image, { className: "c-FeaturedText__image-svg", media: imgurl }))),
            text && (React.createElement(RichText, { className: "c-FeaturedText__text", field: text, tag: "div" })))));
};
export default FeaturedText;
