var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import ColorTile from "./ColorTile/index.js";
import "./styles.scss";
var ColorTiles = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, tiles = _a.tiles;
    var cssClassVariant = tiles.length > 4 ? "c-ColorTiles--more" : "c-ColorTiles--standard";
    return (React.createElement(PlaceholderComponent, __assign({}, props, { customSpaces: true }),
        React.createElement("section", { className: "c-ColorTiles " + cssClassVariant },
            React.createElement(ComponentHeading, { tag: headingTag, field: heading }),
            tiles && tiles.length > 0 && (React.createElement("div", { className: "c-ColorTiles__list" },
                React.createElement("ul", { className: "reset" }, tiles.map(function (item, index) {
                    return React.createElement(ColorTile, { data: item, key: item.id + "-" + index });
                })))))));
};
export default ColorTiles;
