var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { Image } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import "./styles.scss";
var ImageBlock = function (props) {
    var fields = props.fields;
    return (React.createElement(PlaceholderComponent, __assign({}, props),
        React.createElement("div", { className: "c-ImageBlock" },
            React.createElement("div", { className: "c-ImageBlock__container" },
                React.createElement(Image, { className: "c-ImageBlock__image", media: fields.imgurl })))));
};
export default ImageBlock;
