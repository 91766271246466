/* eslint-disable import/first */
import React from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import { RichText } from "@sitecore-jss/sitecore-jss-react";

import imageQuotationMark from "../../assets/img/quotation-mark.png";
import "./styles.scss";

const ReferenceBox = (props) => (
  <PlaceholderComponent {...props}>
    <figure className="c-ReferenceBox">
      <RichText
        field={props.fields.author}
        tag={"figcaption"}
        className="c-ReferenceBox--author"
      />
      <blockquote className="c-ReferenceBox--descr">
        <RichText
          field={props.fields.description}
          tag={"div"}
          className="c-ReferenceBox--descr-text"
        />
        <div
          className="c-ReferenceBox--descr-img"
          style={{ backgroundImage: `url(${imageQuotationMark})` }}
        ></div>
      </blockquote>
    </figure>
  </PlaceholderComponent>
);

export default ReferenceBox;
