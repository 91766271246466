// node_modules
import { getFieldValue, RichText, Image, } from "@sitecore-jss/sitecore-jss-react";
import React from "react";
// local_modules
import { bgGradientFromConfiguration } from "../../../helpers/app/jss/fields/Gradient";
import { headingTagField } from "../../../shared/Component/ComponentHeading";
import { themeClassName } from "../../../helpers/app/jss/fields/Theme";
import { generalLinkIsEmpty } from "../../../helpers/app/jss/fieldTypes/GeneralLink";
import BtnElevator from "../../../shared/BtnElevator";
var MainSliderSlide = function (props) {
    var fields = props.fields;
    var headingSlide = fields.headingSlide, headingTag = fields.headingTag, imageDesktopSlide = fields.imageDesktopSlide, imageMobileSlide = fields.imageMobileSlide, ctaSlide = fields.ctaSlide;
    // Get values from fileds
    var _ctaSlide = getFieldValue(fields, "ctaSlide");
    var _theme = getFieldValue(fields.theme, "value");
    var fieldNoFollow = getFieldValue(fields, "noFollow");
    // ---
    // bgColorGradientStepSlide
    var slideBgGradient = fields.bgColorGradientStepSlide && fields.bgColorGradientStepSlide.fields
        ? bgGradientFromConfiguration(fields.bgColorGradientStepSlide).value
        : null;
    var bgColorClassName = slideBgGradient
        ? slideBgGradient
        : "c-MainSlider__slide--default";
    // ---
    // theme - text colors
    var _themeClassName = themeClassName(fields); // as theme field
    return (React.createElement("div", { className: "c-MainSlider__slide " + bgColorClassName + " swiper-slide" + _themeClassName },
        React.createElement("div", { className: "c-MainSlider__txt" },
            React.createElement("div", null,
                React.createElement(RichText, { className: "c-MainSlider__title", tag: headingTagField(headingTag) || "span", field: headingSlide }),
                _ctaSlide && !generalLinkIsEmpty(_ctaSlide) && (React.createElement("div", { className: "c-MainSlider__cta" },
                    React.createElement(BtnElevator, { field: ctaSlide.value, theme: _theme, noFollow: fieldNoFollow }))))),
        React.createElement("div", { className: "c-MainSlider__img" },
            React.createElement(Image, { className: "c-MainSlider__img--mobile swiper-lazy", field: imageMobileSlide }),
            React.createElement(Image, { className: "c-MainSlider__img--desktop swiper-lazy", field: imageDesktopSlide }),
            React.createElement("div", { className: "swiper-lazy-preloader" }))));
};
export default MainSliderSlide;
