import React from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import { getFieldValue, RichText } from "@sitecore-jss/sitecore-jss-react";

import "./styles.scss";

const ContentBlock = (props) => {
  let componentClass = "c-ContentBlock";

  if (props.fields && props.fields.alignmentContent) {
    const varinatClass = getFieldValue(
      props.fields.alignmentContent,
      "classname"
    );
    if (varinatClass) {
      componentClass += ` ${varinatClass}`;
    }
  }

  return (
    <PlaceholderComponent {...props}>
      <div className={componentClass}>
        <ComponentHeading
          tag={props.fields.headingTag}
          field={props.fields.heading}
          headingIsHidden={props.fields.headingIsHidden}
        />
        <RichText
          field={props.fields.content}
          tag={"div"}
          className="c-ContentBlock__content"
        />
      </div>
    </PlaceholderComponent>
  );
};

export default ContentBlock;
