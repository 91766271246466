/* eslint-disable import/first */
import React, { useEffect } from 'react';
import { RichText } from "@sitecore-jss/sitecore-jss-react";
// import { isServer } from '@sitecore-jss/sitecore-jss';
import "./styles.scss";
var RawHTML = function (props) {
    // let t=setInterval(function() {
    //   console.log('on-server')
    //   if(!isServer()){
    //     console.log('on-browser');
    //     fetch('/dist/sig/static/js/reload.js')
    //     .then((response) => response.text())
    //     .then(txt => eval(txt));
    //     clearInterval(t);
    //   }
    // },100);
    useEffect(function () {
        if (props && props.fields && props.fields.jsFile && props.fields.jsFile.value.length > 0) {
            var script_1 = document.createElement('script');
            script_1.src = "https://www.infinity-group.pl/dist/sig/static/js/" + props.fields.jsFile.value;
            script_1.async = true;
            document.body.appendChild(script_1);
            return function () {
                document.body.removeChild(script_1);
            };
        }
    }, [props]);
    return (React.createElement("div", null,
        React.createElement(RichText, { field: props.fields.content, tag: "div", className: "c-RawHTML__content" })));
};
export default RawHTML;
