import React from "react";
import { Placeholder, VisitorIdentification, } from "@sitecore-jss/sitecore-jss-react";
import Helmet from "react-helmet";
import "what-input";
import { metaTags } from "./metaTags";
// import Loading from "./shared/Loading";
// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import "./assets/styles/app.scss";
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
if (typeof window !== "undefined") {
    var WebFont = require("webfontloader");
    WebFont.load({
        google: {
            families: ["Poppins:300,400,600,700", "sans-serif"],
        },
    });
}
var Layout = function (_a) {
    var route = _a.route, pageIsLoaded = _a.pageIsLoaded /* ,rootId */;
    var bodyAttributes = {
        class: route.fields.bodyClass ? route.fields.bodyClass.value : "",
    };
    var metaTagsData = metaTags(route.fields);
    var classNameFadeIn = pageIsLoaded ? "is-animate" : "";
    var canonicalLink;
    var hrefLangEN;
    var hrefLangPL;
    var hrefLangXDefault;
    var itemLanguage = 'en';
    var noFollowPage;
    var noIndexLinks;
    var jsonLD;
    var disableMainWrapper;
    if (route.fields.canonicalLink && route.fields.canonicalLink.value) {
        canonicalLink = route.fields.canonicalLink.value;
    }
    if (route.fields.hrefLangEN && route.fields.hrefLangEN.value) {
        hrefLangEN = route.fields.hrefLangEN.value;
    }
    if (route.fields.hrefLangPL && route.fields.hrefLangPL.value) {
        hrefLangPL = route.fields.hrefLangPL.value;
    }
    if (route.fields.hrefLangXDefault && route.fields.hrefLangXDefault.value) {
        hrefLangXDefault = route.fields.hrefLangXDefault.value;
    }
    if (route.fields.itemLanguage && route.fields.itemLanguage.value) {
        itemLanguage = route.fields.itemLanguage.value;
    }
    if (route.fields.noFollowPage && route.fields.noFollowPage.value) {
        noFollowPage = 'nofollow';
    }
    if (route.fields.noIndexLinks && route.fields.noIndexLinks.value) {
        noIndexLinks = 'noindex';
    }
    if (route.fields.jsonLD && route.fields.jsonLD.value) {
        jsonLD = route.fields.jsonLD.value;
    }
    if (route.fields.disableMainWrapper && route.fields.disableMainWrapper.value) {
        disableMainWrapper = true;
    }
    var metaRobots = '';
    if (noFollowPage && noIndexLinks) {
        metaRobots = 'noindex, nofollow';
    }
    else if (noFollowPage) {
        metaRobots = 'nofollow';
    }
    else if (noIndexLinks) {
        metaRobots = 'noindex';
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { bodyAttributes: bodyAttributes, meta: metaTagsData, htmlAttributes: { lang: itemLanguage }, script: jsonLD },
            React.createElement("title", null, (route.fields &&
                route.fields.pageTitle &&
                route.fields.pageTitle.value) ||
                "Welcome to New IG Page"),
            canonicalLink && React.createElement("link", { rel: "canonical", href: route.fields.canonicalLink.value }),
            hrefLangEN && React.createElement("link", { rel: "alternate", href: hrefLangEN, hrefLang: "en" }),
            hrefLangPL && React.createElement("link", { rel: "alternate", href: hrefLangPL, hrefLang: "pl" }),
            hrefLangXDefault && React.createElement("link", { rel: "alternate", href: hrefLangXDefault, hrefLang: "x-default" }),
            metaRobots && React.createElement("meta", { name: "robots", content: metaRobots }),
            jsonLD && React.createElement("script", { type: "application/ld+json" }, jsonLD)),
        React.createElement(VisitorIdentification, null),
        React.createElement("header", { id: "header", className: "animation--fadeIn is-animate" },
            React.createElement("div", { className: "wrapper" },
                React.createElement(Placeholder, { name: "jss-header", rendering: route }))),
        React.createElement("div", { id: "main", className: "animation--fadeIn is-animate" }, !disableMainWrapper ? (React.createElement("div", { className: "wrapper" },
            React.createElement(Placeholder, { name: "jss-main", rendering: route }))) : (React.createElement(Placeholder, { name: "jss-main", rendering: route }))),
        React.createElement("footer", { id: "footer", className: "animation--fadeIn " + classNameFadeIn },
            React.createElement(Placeholder, { name: "jss-footer", rendering: route })),
        React.createElement("aside", { id: "aside" },
            React.createElement(Placeholder, { name: "jss-aside", rendering: route }))));
};
export default Layout;
