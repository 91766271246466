var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import "./styles.scss";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
var TextsColumns = function (props) {
    return (React.createElement(PlaceholderComponent, __assign({ customSpaces: true }, props),
        React.createElement("div", { className: "c-TextsColumns" },
            props.fields.heading && (React.createElement(ComponentHeading, { tag: props.fields.headingTag, field: props.fields.heading, className: "c-TextsColumns__heading" })),
            React.createElement("div", { className: "c-TextsColumns__inner" },
                React.createElement(RichText, { className: "c-TextsColumns__col", tag: "div", field: props.fields.col1 }),
                React.createElement(RichText, { className: "c-TextsColumns__col", tag: "div", field: props.fields.col2 })))));
};
export default TextsColumns;
