var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import ExpertTile from "./ExpertTile";
import "./styles.scss";
var ExpertTileList = function (props) {
    var large = props.large;
    return (React.createElement("div", { className: "c-ExpertTileList " + (large ? "c-ExpertTileList--large" : "c-ExpertTileList--multiple") }, props.data.map(function (item, index) {
        return (React.createElement(ExpertTile, __assign({}, item, { step: props.step, oneExpert: props.oneExpert, key: item.id + "-" + index })));
    })));
};
export default ExpertTileList;
