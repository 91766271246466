import React from "react";
import { headingTagField } from "../../../shared/Component/ComponentHeading";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { bgGradientFromConfiguration } from "../../../helpers/app/jss/fields/Gradient";

import "./styles.scss";

const ColorTile = (props) => {
  const { fields } = props.data;
  const _tileGradient =
    fields.tileGradient && fields.tileGradient.fields
      ? bgGradientFromConfiguration(fields.tileGradient).value
      : null;
  const bgColorClass = _tileGradient ? _tileGradient : "c-ColorTile--default";

  return (
    <li className={`c-ColorTile ${bgColorClass}`}>
      <div>
        <Text
          className="c-ColorTile__title"
          tag={headingTagField(fields.headingTag) || "h3"}
          field={fields.heading}
        />
        {fields.tileDescription && (
          <RichText
            className="c-ColorTile__description"
            tag="div"
            field={fields.tileDescription}
          />
        )}
      </div>
    </li>
  );
};
export default ColorTile;
