var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import DeliverablesList from "./DeliverablesList";
import { bgGradientTypeClassName } from "../../helpers/app/jss/fields/Gradient";
import withComponentParametersItem from "../../lib/ComponentParametersItem";
import "./styles.scss";
var DeliverablesSection = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, deliverablesList = _a.deliverablesList;
    var bgGradientType = bgGradientTypeClassName(props.fields);
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false }, props),
        React.createElement("div", { className: "c-DeliverablesSection " + bgGradientType },
            heading && (React.createElement(ComponentHeading, { tag: headingTag, field: heading, className: "c-DeliverablesSection__title align-center" })),
            deliverablesList && React.createElement(DeliverablesList, { data: deliverablesList }))));
};
// export default DeliverablesSection;
// export default withComponentParametersData({ name: 'connectedQuery' })(DeliverablesSection);
export default withComponentParametersItem({ name: "connectedQuery" })(DeliverablesSection);
