var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import DeliverableListItem from "../DeliverablesItem";
var DeliverableList = function (props) {
    return (React.createElement(React.Fragment, null,
        React.createElement("ul", { className: "c-DeliverablesSection__list reset" }, props.data.map(function (item, index) {
            return React.createElement(DeliverableListItem, __assign({}, item, { key: item.id + "-" + index }));
        }))));
};
export default DeliverableList;
