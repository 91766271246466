// node_modules
import React, { useState, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { RichText, getFieldValue } from "@sitecore-jss/sitecore-jss-react";

// local_modules
import withComponentParametersItem from "../../lib/ComponentParametersItem";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import { bgGradientTypeClassName } from "../../helpers/app/jss/fields/Gradient";
import { debounce } from "../../helpers/app/utils/debounce";

// fragments
import "./styles.scss";

const IntroBannerProductDeliveryProcess = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const resize = debounce(() => {
      setIsMobile(window.innerWidth < 768);
    }, 50);

    if (typeof window !== "undefined") {
      window.addEventListener("resize", resize);
      setIsMobile(window.innerWidth < 768);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", resize);
      }
    };
  }, [isMobile]);

  const screenSizeCheck = (stepValue) => {
    if (isMobile) {
      return stepsMobileVersion(stepValue);
    } else {
      return stepsDesktopVersion(stepValue);
    }
  };

  const { fields } = props;
  const showSteps = getFieldValue(fields, "showsteps");
  const stepValue = getFieldValue(fields, "step");
  const productsDelivery = fields.productsDelivery;
  let bgGradientType = "";

  if (typeof stepValue && stepValue !== undefined) {
    bgGradientType = bgGradientTypeClassName(fields);
  } else {
    bgGradientType = ""; //'gradient--step1'; // default gradinet for page "services/implementations"
  }

  const $component = {
    backgroundClass: bgGradientType,
  };

  const stepsMobileVersion = (activeStep) => {
    return (
      <div className="c-IntroBannerProductDeliveryProcess__steps__step is-active">
        <span className="step__number is-active">
          {parseInt(activeStep) < 10 ? `0${activeStep}` : activeStep}
        </span>
      </div>
    );
  };

  const stepsDesktopVersion = (activeStep) => {
    let activeClass = "";
    return (
      <ul className="reset">
        {productsDelivery &&
          productsDelivery.length > 0 &&
          productsDelivery.map((product) => {
            const navigationId = getFieldValue(product.fields, "navigationId");
            const showMoreLink = getFieldValue(product.fields, "showMoreLink");
            const navigationTitle = getFieldValue(
              product.fields,
              "navigationTitle"
            );
            activeClass =
              parseInt(navigationId) === parseInt(activeStep)
                ? "is-active"
                : "";

            return (
              product && (
                <li
                  className={`c-IntroBannerProductDeliveryProcess__steps__step ${activeClass}`}
                  key={`${navigationId}-step-delivery-process`}
                >
                  <NavLink to={showMoreLink.href} className="reset">
                    <span className={`step__name ${activeClass}`}>
                      {navigationTitle}
                    </span>
                    <span className={`step__number ${activeClass}`}>
                      {parseInt(navigationId) <= 10
                        ? `0${navigationId}`
                        : "step"}
                    </span>
                  </NavLink>
                </li>
              )
            );
          })}
      </ul>
    );
  };

  return (
    <PlaceholderComponent
      withBg={true}
      customSpaces={true}
      componentSettings={$component}
      {...props}
    >
      <div className="c-IntroBannerProductDeliveryProcess">
        {stepValue > 0 && showSteps && (
          <nav className="c-IntroBannerProductDeliveryProcess__steps">
            {screenSizeCheck(stepValue)}
          </nav>
        )}
        <div className="c-IntroBannerProductDeliveryProcess__introduction">
          <RichText
            tag="h1"
            className="c-IntroBannerProductDeliveryProcess__introduction__title"
            field={fields.heading}
          />
          <RichText
            tag="div"
            className="c-IntroBannerProductDeliveryProcess__introduction__text"
            field={fields.description}
          />
        </div>
      </div>
    </PlaceholderComponent>
  );
};

//export default IntroBannerProductDeliveryProcess;
// export default withComponentParametersData({ name: 'connectedQuery' })(IntroBannerProductDeliveryProcess);
export default withComponentParametersItem({ name: "connectedQuery" })(
  IntroBannerProductDeliveryProcess
);
