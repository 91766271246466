var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import { RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import "./styles.scss";
var CaseStudiesBox = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, withBg = _a.withBg, title = _a.title, subTitle = _a.subTitle, imgDesktop = _a.imgDesktop, imgTablet = _a.imgTablet, imgMobile = _a.imgMobile, link = _a.link;
    var _withBg = withBg.value;
    var _imgDesktopValue = imgDesktop.value;
    var _imgTabletValue = imgTablet.value;
    var _imgMobileValue = imgMobile.value;
    return (React.createElement(PlaceholderComponent, __assign({ withBg: _withBg, withoutSpaces: true }, props),
        React.createElement("div", { className: "c-CaseStudies" },
            heading && (React.createElement(ComponentHeading, { tag: headingTag, field: heading, className: "c-CaseStudies__heading align-center" })),
            React.createElement(Link, { field: link },
                React.createElement("figure", { className: "c-CaseStudies__image" },
                    imgDesktop && (React.createElement("picture", null,
                        imgMobile && (React.createElement("source", { media: "(max-width: 479px)", srcSet: _imgMobileValue.src })),
                        imgTablet && (React.createElement("source", { media: "(max-width: 1200px)", srcSet: _imgTabletValue.src })),
                        imgDesktop && (React.createElement("source", { media: "(min-width: 1200px)", srcSet: _imgDesktopValue.src })),
                        React.createElement("img", { src: _imgDesktopValue.src, alt: _imgDesktopValue.alt }))),
                    React.createElement("figcaption", { className: "c-CaseStudies__text" },
                        title && (React.createElement(RichText, { field: title, tag: "h3", className: "c-CaseStudies__text--title" })),
                        subTitle && (React.createElement(RichText, { field: subTitle, tag: "h4", className: "c-CaseStudies__text--subtitle" }))))))));
};
export default CaseStudiesBox;
