import React from "react";
import { Image, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import fieldImage from "../../helpers/app/jss/fieldTypes/Image";

import "./styles.scss";

const IntroBannerImage = (props) => {
  const { fields } = props;

  let _bgimgurl = getFieldValue(fields, "bgimgurl");
  _bgimgurl = fieldImage(_bgimgurl);

  const divParentStyle = {
    backgroundImage: _bgimgurl.backgroundSrc,
  };

  return (
    <PlaceholderComponent withContainer={false} withoutSpaces={true} {...props}>
      <div className="c-IntroBannerImage" style={divParentStyle}>
        <div className="c-IntroBannerImage__imageContainer">
          <Image
            className="c-IntroBannerImage__image"
            media={props.fields.contentImage}
          />
        </div>
      </div>
    </PlaceholderComponent>
  );
};

export default IntroBannerImage;
