var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import { getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// internal_modules
// fragments
import "./styles.scss";
var ResultsNumbers = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, headingIsHidden = _a.headingIsHidden, resultsValues = _a.resultsValues;
    var _b = useState(false), startAnimation = _b[0], setStartAnimation = _b[1];
    var rootRef = useRef(null);
    useEffect(function () {
        var observer;
        if (typeof window !== "undefined") {
            var intersectionObserverCallback = function (entries) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        setStartAnimation(true);
                    }
                });
            };
            observer = new IntersectionObserver(intersectionObserverCallback, {
                threshold: 0,
            });
            observer.observe(rootRef.current);
        }
    });
    return (React.createElement(PlaceholderComponent, __assign({}, props),
        React.createElement("div", { className: "c-ResultsNumbers" },
            React.createElement(ComponentHeading, { className: "align-center", field: heading, tag: headingTag, headingIsHidden: headingIsHidden }),
            resultsValues.length > 0 && (React.createElement("ul", { ref: rootRef, className: "c-ResultsNumbers__list" }, resultsValues.map(function (item, index) {
                var itemNumber = getFieldValue(item.fields, "number");
                var itemMeasure = getFieldValue(item.fields, "measure");
                var itemDescription = getFieldValue(item.fields, "description");
                return (React.createElement("li", { key: item + "-" + index, className: "c-ResultsNumbers__item" },
                    React.createElement("div", null,
                        React.createElement("span", { className: "c-ResultsNumbers__number" },
                            !startAnimation && (React.createElement("span", { className: "c-ResultsNumbers__number" }, "0")),
                            startAnimation && itemNumber && (React.createElement(CountUp, { start: 0, delay: 0.5, end: Number(itemNumber), duration: 2 }))),
                        itemMeasure && (React.createElement("span", { className: "c-ResultsNumbers__measure" }, itemMeasure))),
                    itemDescription && (React.createElement("div", { className: "c-ResultsNumbers__description" }, itemDescription))));
            }))))));
};
export default ResultsNumbers;
