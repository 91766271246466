var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import ExpertTileList from "./ExpertTileList";
import "./styles.scss";
var ExpertsTiles = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, experts = _a.experts, step = _a.step;
    return (React.createElement(PlaceholderComponent, __assign({}, props),
        React.createElement("div", { className: "c-ExpertsTiles" },
            heading && (React.createElement(ComponentHeading, { className: "c-ExpertsTiles__heading align-center", tag: headingTag, field: heading })),
            experts && (React.createElement(ExpertTileList, { data: experts, step: step, large: experts.length === 1 })))));
};
export default ExpertsTiles;
