// TODO: dodać funkcjonalność jeśli pojawią popupy w projekcie; wtedy również zrobić refactor komponentyu (./src/shared/Cookies)

export function popupsWrapper(showPopup) {
    if (typeof window !== 'undefined') {
        const $html = document.getElementsByTagName( 'html' )[0];
        const classNamePopupOpened = 'is-opened-popup';

        if (showPopup) {
            $html.classList.add(classNamePopupOpened)
        } else {
            $html.classList.remove(classNamePopupOpened)
        }
    }
}