import { Form } from "@sitecore-jss/sitecore-jss-react-forms";
import React from "react";
import { withRouter } from "react-router-dom";
import { sitecoreApiHost, sitecoreApiKey } from "../../temp/config";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";

import "./styles.scss";

const From = (props) => {
  const { fields } = props;
  return (
    <PlaceholderComponent {...props}>
      <Form
        form={fields}
        sitecoreApiHost={sitecoreApiHost}
        sitecoreApiKey={sitecoreApiKey}
      />
    </PlaceholderComponent>
  );
};

export default withRouter(From);
