/* eslint-disable import/first */
// node_modules
import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
var CaseStudyShortInfoItem = function (props) {
    var title = getFieldValue(props.fields, "title");
    var description = getFieldValue(props.fields, "description");
    return (React.createElement("li", { className: "c-CaseStudyShortInfo__item" },
        React.createElement("span", { children: title }),
        React.createElement("span", { children: description })));
};
export default CaseStudyShortInfoItem;
