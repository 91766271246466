var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import "./styles.scss";
var Divider = function (props) {
    var withBg = props.fields.withBg;
    var _withBg = !!withBg.value;
    return (React.createElement(PlaceholderComponent, __assign({ withoutSpaces: true, withBg: _withBg }, props),
        React.createElement("div", { className: "c-Divider" },
            React.createElement("div", { className: "c-Divider__line c-Divider__line" + (_withBg ? "--bg" : "") }))));
};
export default Divider;
