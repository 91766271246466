import React from "react";

// import { LogoElement } from "./shared/Navigation";
// import { IntroTextRenderer } from "./components/IntroText";
import { onAnimationEnd as fadeIn_onAnimationEnd } from "./helpers/app/shared/animations/animation-fadeIn";
import "./components/IntroText/styles.scss";
// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const NotFound = ({ context = { site: { name: "" }, language: "" } }) => {
  console.log(context);
  return (
    <React.Fragment>
      <header
        id="header"
        className={`animation--fadeIn is-animate`}
        onAnimationEnd={(event) => fadeIn_onAnimationEnd(event)}
      >
        <div className="wrapper">
          <div className="c-Navigation">
            {/* <LogoElement elClass="c-Navigation__hdr" /> */}
          </div>
        </div>
      </header>
      <div
        id="main"
        className={`animation--fadeIn is-animate`}
        onAnimationEnd={(event) => fadeIn_onAnimationEnd(event)}
      >
        <div className="wrapper">
          <h1>Page not found</h1>
          <p>This page does not exist.</p>
          <p>
            Site: {context.site && context.site.name}
            <br />
            Language: {context.language}
          </p>
          {/* <IntroTextRenderer
            fields={fields}
            containerClass={"c-IntroText"}
            other={this.superProps}
            {...this.props}
          /> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
