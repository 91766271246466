var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { RichText, Image, Text, getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
import { generalLinkIsEmpty } from "../../helpers/app/jss/fieldTypes/GeneralLink";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import BtnElevator from "../../shared/BtnElevator";
import "./styles.scss";
var BannerImage = function (props) {
    var fields = props.fields;
    var componentClass = "c-BannerImage";
    var fieldImgBgUrl = getFieldValue(fields, "imgBgUrl");
    var fieldImgLogoUrl = getFieldValue(fields, "imgLogoUrl");
    var fieldSubheading = getFieldValue(fields, "subheading");
    var fieldText = getFieldValue(fields, "text");
    var fieldButton = getFieldValue(fields, "button");
    var fieldNoFollow = getFieldValue(fields, "noFollow");
    if (fields && fields.variant) {
        var varinatClass = getFieldValue(props.fields.variant, "classname");
        if (varinatClass) {
            componentClass += " " + varinatClass;
        }
    }
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, withoutSpaces: false }, props),
        React.createElement("div", { className: componentClass }, fieldImgBgUrl && (React.createElement("div", { className: "c-BannerImage__imgBg" },
            React.createElement(Image, { loading: "lazy", field: fields.imgBgUrl, className: "c-BannerImage__imgBg__bg" }),
            React.createElement("div", { className: "container" },
                fieldText && (React.createElement(RichText, { field: fields.text, tag: "p", className: "c-BannerImage__text" })),
                React.createElement(ComponentHeading, { tag: fields.headingTag, field: fields.heading, className: "c-BannerImage__heading", headingIsHidden: fields.headingIsHidden }),
                fieldSubheading && (React.createElement(Text, { field: fields.subheading, tag: "p", className: "c-BannerImage__subHeading" })),
                fieldImgLogoUrl && (React.createElement(Image, { loading: "lazy", field: fields.imgLogoUrl, className: "c-BannerImage__imgLogo" })),
                fieldButton && !generalLinkIsEmpty(fieldButton) && (React.createElement(BtnElevator, { field: fields.button.value, theme: "light", noFollow: fieldNoFollow }))))))));
};
export default BannerImage;
