import React from 'react';
import { Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ArrowLeft from "../../../assets/icons/ArrowLeft";
import {headingTagField} from '../../../shared/Component/ComponentHeading';

/**
 * This is a single tab within the tabs sample component. These are added to the tabs placeholder.
 * This component demonstrates conditionally altering rendering when in the Sitecore Experience Editor to improve
 * author experience.
 */
const AccordionItem = (props) => {
  const _isActive = props.isActive;
  const isActive = {
    class: _isActive ? ' is-active' : '',
    button_ariaExpanded: _isActive ? 'true' : 'false',
    section_ariaHidden: _isActive ? 'false' : 'true',
  };
  const { fields, index } = props;

  const content_TextsColumns = !!(fields.col1 && fields.col2)
  const content_TextsColumnsClassName = content_TextsColumns ? ' c-Accordion__content--col2' : '';

  // TODO: dodać obsługę aria-*
  return (
    <section className={`c-Accordion__item${isActive.class}`} key={`tab${index}`}>
      <div className="c-Accordion__title" role="tab" type="button"
        id={`collapsible-${index}`}
        aria-expanded={isActive.button_ariaExpanded}
        aria-controls={`collapsible-${index}`}
        tabIndex = "0"
        onClick={(event) => props.onTitleClick(event)}
        onKeyPress = {(event) => props.handleEnter(event)}>
        <div>
          <Text tag={headingTagField(fields.headingTag) || 'h3'} field={fields.heading} />
          <ArrowLeft className="c-Accordion__title__icon" aria-hidden="true" />
        </div>
      </div>

      <div className={`c-Accordion__content${content_TextsColumnsClassName}`} role="tabpanel"
        id={`collapsible-${index}`}
        aria-labelledby=""
        aria-hidden={isActive.section_ariaHidden}>
        <div className="container container--nospaces">
            {/* Template Component ContentBlock */}
            { fields.content &&
              <RichText tag="div" field={fields.content} />
            }
            {/* Template Component TextsColumns */}
            { content_TextsColumns &&
              <>
                <RichText className="c-Accordion__content__col2" tag='div' field={fields.col1} />
                <RichText className="c-Accordion__content__col2" tag='div' field={fields.col2} />
              </>
            }
        </div>
      </div>
    </section>
  );
};

export default withSitecoreContext()(AccordionItem);