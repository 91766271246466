var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React, { useCallback, useEffect, useRef, useState, } from "react";
import { 
// ComponentFields,
getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Thumbs, Autoplay, Lazy, } from "swiper";
import { withTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// fragments
import MainSliderSlide from "./MainSliderSlide";
import MainSliderNavThumb from "./MainSliderNavThumb";
import "./styles.scss";
SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    Thumbs,
    Autoplay,
    Lazy,
    A11y,
]);
var MainSlider = function (props) {
    var fields = props.fields;
    // static data
    var heading = fields.heading, headingTag = fields.headingTag, headingIsHidden = fields.headingIsHidden, slides = fields.slides;
    var slideNumberStart = Number(getFieldValue(fields, "slideNumberStart"));
    var nextText = props.t("mainslider-next-slide");
    var slidesLength = (slides && slides.length) || 0;
    // state
    var _a = useState(null), slidesPerView = _a[0], setSlidesPerView = _a[1];
    var _b = useState(false), navigation = _b[0], setNavigation = _b[1];
    var _c = useState(""), nextTextLabel = _c[0], setNextTextLabel = _c[1];
    var refMainSlider = useRef(null);
    var refThumbsSlider = useRef(null);
    // configs swiper sliders
    var swiperParamsMainSlider = {
        initialSlide: slideNumberStart || 0,
        slidesPerView: 1,
        loop: true,
        autoplay: {
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
        },
        navigation: true,
        lazy: true,
    };
    var swiperParamsThumbsSlider = {
        initialSlide: slideNumberStart || 0,
        // navigation: true,
        slidesPerView: 1,
        spaceBetween: 100,
        breakpoints: {
            640: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 75,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
                loopedSlides: 4,
            },
        },
        centeredSlides: false,
        loop: false,
        slideToClickedSlide: true,
        lazy: true,
    };
    // update data
    var onSlideChangeMainSlider = useCallback(function () {
        // console.log(refMainSlider.current && refMainSlider.current.autoplay);
        if (slidesPerView === 1) {
            if (refMainSlider.current.realIndex === slidesLength - 1) {
                refThumbsSlider.current && refThumbsSlider.current.slideTo(0);
            }
            else {
                refThumbsSlider.current &&
                    refThumbsSlider.current.slideTo(refMainSlider.current.realIndex + 1);
            }
        }
    }, [slidesPerView, slidesLength]);
    useEffect(function () {
        if (slidesPerView === 1) {
            setNavigation(false);
            setNextTextLabel(nextText);
            if (refMainSlider.current.realIndex === slidesLength - 1) {
                refThumbsSlider.current && refThumbsSlider.current.slideTo(0);
            }
            else {
                refThumbsSlider.current &&
                    refThumbsSlider.current.slideTo(refMainSlider.current.realIndex + 1);
            }
        }
        else {
            setNavigation(true);
            setNextTextLabel("");
        }
    }, [slidesPerView, slidesLength, nextText, setNavigation, setNextTextLabel]);
    return (React.createElement(PlaceholderComponent, __assign({ withoutSpaces: false, withContainer: false }, props),
        React.createElement(ComponentHeading, { tag: headingTag, field: heading, headingIsHidden: headingIsHidden }),
        React.createElement("div", { className: "c-MainSlider" },
            React.createElement("div", { className: "c-MainSlider__gallery" },
                React.createElement(Swiper, __assign({ thumbs: {
                        swiper: refThumbsSlider.current,
                    }, onInit: function (swiper) {
                        refMainSlider.current = swiper;
                        // console.log(
                        //   refMainSlider.current && refMainSlider.current.autoplay
                        // );
                    }, onSlideChange: onSlideChangeMainSlider }, swiperParamsMainSlider), slides.map(function (item, index) {
                    var fields = item.fields, id = item.id;
                    var durationSlide = getFieldValue(fields, "durationSlide");
                    return (React.createElement(SwiperSlide, { key: id + "-" + index, "data-swiper-autoplay": durationSlide || "4000" },
                        React.createElement(MainSliderSlide, { index: index, id: id, fields: fields })));
                }))),
            React.createElement("div", { className: "c-MainSlider__thumbs" },
                React.createElement(Swiper, __assign({ onInit: function (swiper) {
                        refThumbsSlider.current = swiper;
                    }, onSlideChange: onSlideChangeMainSlider, onBreakpoint: function (_swiper, swiperOptions) {
                        setSlidesPerView(swiperOptions.slidesPerView);
                    }, navigation: navigation }, swiperParamsThumbsSlider), slides.map(function (item, index) {
                    var fields = item.fields, id = item.id;
                    return (React.createElement(SwiperSlide, { key: id + "-" + index },
                        React.createElement(MainSliderNavThumb, { index: index, id: id, fields: fields, nextTextLabel: nextTextLabel })));
                }))))));
};
export default withTranslation()(MainSlider);
