/* eslint-disable import/first */
// node_modules
import React from "react";
import { Image, RichText } from "@sitecore-jss/sitecore-jss-react";
// locla_modules
import ArrowProcess from "../../../assets/icons/ArrowProcess";
// fragments
import "./styles.scss";
var IconsGroupItem = function (props) {
    var data = props.data, arrowsOfProcess = props.arrowsOfProcess;
    var fields = data.fields;
    var iconTextDescription = fields.iconTextDescription, iconTextImage = fields.iconTextImage;
    return (React.createElement("li", { className: "c-IconsGroup__item" },
        React.createElement("div", null,
            iconTextImage && (React.createElement("div", { className: "c-IconsGroup__itemImage" },
                React.createElement(Image, { field: iconTextImage }))),
            iconTextDescription && (React.createElement("div", { className: "c-IconsGroup__itemText" },
                React.createElement("div", null,
                    React.createElement(RichText, { tag: "p", field: iconTextDescription }))))),
        arrowsOfProcess && (React.createElement("div", { className: "c-IconsGroup__itemImage", style: { overflow: "initial" } },
            React.createElement(ArrowProcess, { width: "50px", className: "c-IconsGroup__itemArrow", "aria-hidden": "true" })))));
};
export default IconsGroupItem;
