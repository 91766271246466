var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { RichText, Image } from "@sitecore-jss/sitecore-jss-react";
// Shared components
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import "./styles.scss";
var BannerText = function (props) {
    var fields = props.fields;
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, withoutSpaces: false }, props),
        React.createElement("div", { className: "c-BannerText" },
            React.createElement("div", { className: "c-BannerText__img--desktop" },
                React.createElement(Image, { loading: "lazy", field: fields.bgimgurlDesktop })),
            React.createElement("div", { className: "c-BannerText__img--mobile" },
                React.createElement(Image, { loading: "lazy", field: fields.bgimgurlMobile })),
            React.createElement("div", { className: "c-BannerText__text" },
                React.createElement(RichText, { field: fields.topTitle, tag: "div", className: "c-BannerText__textTop" }),
                React.createElement(RichText, { field: fields.bottomTitle, tag: "div", className: "c-BannerText__textBottom" })))));
};
export default BannerText;
