/* eslint-disable import/first */
// node_modules
import React from "react";
import { Image, getFieldValue, } from "@sitecore-jss/sitecore-jss-react";
// fragments
import "./styles.scss";
var ContactTilesItem = function (props) {
    var _a = props.fields, companyPosition = _a.companyPosition, homeOffice = _a.homeOffice;
    companyPosition = getFieldValue(companyPosition.fields, "positionName");
    //   const officeDescription =
    //     homeOffice && getFieldValue(homeOffice.fields, "officeDescription");
    var officeCountryFlag = homeOffice && getFieldValue(homeOffice.fields, "officeCountryFlag");
    var personName = getFieldValue(props.fields, "personName");
    var contactDetailsPhone = getFieldValue(props.fields, "contactDetailsPhone");
    var contactDetailsMail = getFieldValue(props.fields, "contactDetailsMail");
    function pushPhoneDatalayer() {
        window.dataLayer.push({
            'event': 'click_phone',
            'page_path': window.location.pathname,
            'click_text': contactDetailsPhone
        });
    }
    function pushEmailDatalayer() {
        window.dataLayer.push({
            'event': 'click_email',
            'page_path': window.location.pathname,
            'click_text': contactDetailsMail
        });
    }
    var _telHref = "tel:" + contactDetailsPhone;
    var _mailtoHref = "mailto:" + contactDetailsMail;
    return (React.createElement("div", { className: "c-ContactTile" },
        React.createElement("div", { className: "c-ContactTile__image" },
            React.createElement(Image, { field: props.fields.galleryImageAlternative })),
        React.createElement("div", { className: "c-ContactTile__innerContent" },
            homeOffice && homeOffice.fields && (React.createElement("div", { className: "c-ContactTile__office" }, officeCountryFlag && (React.createElement("div", { className: "c-ContactTile__officeImg" },
                React.createElement(Image, { field: officeCountryFlag }))))),
            React.createElement("div", { className: "c-ContactTile__personData" },
                React.createElement("p", null,
                    personName && (React.createElement("span", { className: "c-ContactTile__name font-weight--700" }, personName)),
                    companyPosition && (React.createElement("span", { className: "c-ContactTile__position font-weight--300" }, companyPosition)),
                    contactDetailsPhone && (React.createElement("span", { className: "c-ContactTile__phone font-weight--300", onClick: pushPhoneDatalayer },
                        React.createElement("a", { href: _telHref }, contactDetailsPhone))),
                    contactDetailsMail && (React.createElement("span", { className: "c-ContactTile__mail font-weight--300", onClick: pushEmailDatalayer },
                        React.createElement("a", { href: _mailtoHref }, contactDetailsMail))))))));
};
export default ContactTilesItem;
