var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { NavLink } from "react-router-dom";
import { Text, Link } from "@sitecore-jss/sitecore-jss-react";
import { loader as gqlLoader } from "graphql.macro";
import { withTranslation } from "react-i18next";
// local_modules
import GraphQLData from "../../lib/GraphQLData";
import { debounce } from "../../helpers/app/utils/debounce";
import ArrowRight from "../../assets/icons/ArrowRight";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import LogoAsset from "../../assets/img/infinity-group.svg";
import logoWhite from "../../assets/img/infinity-group-white.svg";
// fragments
import "./index.scss";
var NavigationLanguagesQuery = gqlLoader("./queryLanguages.graphql");
var Navigation = /** @class */ (function (_super) {
    __extends(Navigation, _super);
    function Navigation(props) {
        var _this = _super.call(this, props) || this;
        _this.handlerResize = debounce(function () {
            if (typeof window !== "undefined") {
                if (window.innerWidth > 1200) {
                    _this.setState({ open: false });
                }
            }
        }, 50);
        _this.handleDocumentClick = function (event) {
            var navigationInner = document.querySelector(".c-Navigation__inner");
            if (!navigationInner.contains(event.target)) {
                _this.setState({
                    expandedLanguageDroplist: false,
                    currentSubgroup: "",
                });
            }
        };
        _this.triggerNav = function (e) {
            e.preventDefault();
            _this.setState({
                open: !_this.state.open,
                currentSubgroup: "",
                openedOnce: _this.state.open || !_this.state.open
            });
        };
        _this.triggerSubgroup = function (e) {
            e.preventDefault();
            var _id = e.target.getAttribute("data-id");
            if (!_id) {
                _id = e.target.parentNode.parentNode.getAttribute("data-id");
                if (!_id) {
                    _id = e.target.parentNode.getAttribute("data-id");
                }
            }
            _this.setState({
                currentSubgroup: _this.state.currentSubgroup === _id ? "" : _id,
            });
        };
        _this.onFocusHandler = function (event) {
            var subItems = event.target.parentNode.querySelectorAll(".c-Navigation__subitem .c-Navigation__anchor");
            subItems.forEach(function (items) { return items.setAttribute("tabindex", "-1"); });
        };
        _this.onBlurHandler = function (event) {
            var subItems = event.target.parentNode.parentNode.parentNode.parentNode.querySelectorAll(".c-Navigation__anchor");
            var lastSubItem = subItems[subItems.length - 1];
            if (lastSubItem === event.target) {
                _this.setState({
                    currentSubgroup: "",
                });
            }
        };
        _this.onKeyDown = function (event) {
            var subItems = event.target.parentNode.querySelectorAll(".c-Navigation__subitem .c-Navigation__anchor");
            if (event.key === "Enter" || event.key === " ") {
                event.preventDefault();
                if (!_this.state.currentSubgroup) {
                    _this.triggerSubgroup(event);
                    subItems.forEach(function (items) { return items.setAttribute("tabindex", "0"); });
                }
                else if (_this.state.currentSubgroup) {
                    _this.setState({
                        currentSubgroup: "",
                    });
                    subItems.forEach(function (items) { return items.setAttribute("tabindex", "-1"); });
                }
            }
        };
        _this.onRedirect = function (event) {
            _this.setState({
                open: false,
                openedOnce: true
            });
        };
        _this.state = {
            open: false,
            expandedLanguageDroplist: false,
            currentSubgroup: "",
            openedOnce: false
        };
        return _this;
    }
    Navigation.prototype.componentDidMount = function () {
        if (typeof window !== "undefined") {
            document.addEventListener("click", this.handleDocumentClick);
            window.addEventListener("resize", this.handlerResize);
        }
    };
    Navigation.prototype.componentWillUnmount = function () {
        if (typeof window !== "undefined") {
            document.removeEventListener("click", this.handleDocumentClick);
            window.removeEventListener("resize", this.handlerResize);
        }
    };
    Navigation.prototype.componentDidUpdate = function (prevProps, _prevState) {
        if (this.props.route &&
            this.props.route.name !== prevProps.route.name /*&& this.state.open*/) {
            // need this to close nav after route change
            this.setState({
                open: false,
                currentSubgroup: "",
            });
        }
    };
    Navigation.prototype.handlerToggleDropdownLanguages = function (event) {
        event.persist();
        this.setState({
            expandedLanguageDroplist: !this.state.expandedLanguageDroplist,
        });
    };
    Navigation.prototype.render = function () {
        var items = this.props.fields.items;
        var languages = { children: [{ name: "pl-PL", field: { value: "pl" } }, { name: "en", field: { value: "en" } }] };
        return (React.createElement("div", { className: "c-Navigation" + (this.state.open ? " c-Navigation--open" : this.state.openedOnce ? "" : " c-Navigation--hidden") },
            React.createElement(LogoElement
            // itemId={this.props.route.itemId}
            , { 
                // itemId={this.props.route.itemId}
                currentLanguage: this.props.i18n.language, className: "c-Navigation__hdr" }),
            React.createElement("button", { className: "c-Navigation__trigger", onClick: this.triggerNav },
                React.createElement("span", { "aria-hidden": "true" }),
                React.createElement("span", { "aria-hidden": "true" }),
                React.createElement("span", { "aria-hidden": "true" }),
                React.createElement("span", { className: "sr-only" }, "Toggle Menu")),
            React.createElement("div", { className: "c-Navigation__list" },
                React.createElement("nav", { className: "c-Navigation__inner" +
                        (this.state.open ? " c-Navigation__inner--active" : this.state.openedOnce ? "" : " c-Navigation__inner--hidden") },
                    React.createElement("ul", { className: "c-Navigation__group" },
                        React.createElement("li", { className: "c-Navigation__hdr c-Navigation__hdr--inside" },
                            React.createElement(Logo, { img: logoWhite, currentLanguage: this.props.i18n.language })),
                        items && (React.createElement(NavItems, { data: items, triggerSubgroup: this.triggerSubgroup, currentSubgroup: this.state.currentSubgroup, onFocusHandler: this.onFocusHandler, onBlurHandler: this.onBlurHandler, onKeyDown: this.onKeyDown, onRedirect: this.onRedirect })),
                        languages && (React.createElement(LanguageSwitcher, __assign({ stateExpandedLanguageDroplist: this.state.expandedLanguageDroplist, handlerToggleDropdown: this.handlerToggleDropdownLanguages.bind(this), currentLanguage: this.props.i18n.language }, languages))))))));
    };
    return Navigation;
}(React.Component));
function LanguageSwitcher(props) {
    var classesButton = "c-Navigation__button c-Navigation__anchor c-Navigation__groupanchor--dropdown c-Navigation__groupanchor--lng";
    var classIsActive = props.stateExpandedLanguageDroplist
        ? " is-expanded c-Navigation__groupanchor--active"
        : "";
    return (React.createElement("li", { className: "c-Navigation__item" },
        React.createElement("div", { className: "" + classesButton + classIsActive, onClick: function (event) { return props.handlerToggleDropdown(event); } },
            props.currentLanguage.substring(0, 2),
            React.createElement(ArrowRight, { className: "c-Navigation__arrowright" })),
        React.createElement("ul", { className: props.stateExpandedLanguageDroplist ? "is-expanded" : "" }, props.children.map(function (item) {
            return (item.name.toLowerCase() !== props.currentLanguage.toLowerCase() && (React.createElement("li", { key: "lng-" + item.name },
                React.createElement(NavLink, { activeClassName: "", to: _setLocationPath({
                        currentLanguage: props.currentLanguage.toLowerCase(),
                        language: item.name.toLowerCase(),
                    }), className: "c-Navigation__anchor" },
                    React.createElement(Text, { field: item.field })))));
        }))));
}
function CustomLink(props) {
    switch (props.data.linktype) {
        case "external":
            return React.createElement(Link, { field: props.data, className: "c-Navigation__anchor" });
        default:
            return (React.createElement(NavLink, { activeClassName: "is-active", to: props.data.href, className: "c-Navigation__anchor", onFocus: function (event) { return props.onFocusHandler(event); } },
                React.createElement(Text, { field: props.itemTitle || { value: props.data.text } })));
    }
}
function NavItems(props) {
    return (
    //'nav'
    props.data.map(function (item) {
        var itemTitle = item.fields.title;
        if (item.fields.groups && item.fields.groups.length > 0) {
            return (React.createElement("li", { key: item.id, className: "c-Navigation__item c-Navigation__item--button" },
                React.createElement("button", { "data-id": item.id, onClick: props.triggerSubgroup, className: "c-Navigation__button c-Navigation__groupanchor c-Navigation__anchor  c-Navigation__groupanchor--dropdown" +
                        (props.currentSubgroup === item.id
                            ? " c-Navigation__groupanchor--active"
                            : ""), onFocus: function (event) { return props.onFocusHandler(event); }, onKeyDown: function (event) { return props.onKeyDown(event); } },
                    React.createElement("div", null,
                        React.createElement(Text, { field: itemTitle }),
                        React.createElement(ArrowRight, { className: "c-Navigation__arrowright" }))),
                React.createElement("div", { className: "c-Navigation__inner" +
                        (props.currentSubgroup === item.id
                            ? " c-Navigation__inner--active"
                            : "") },
                    React.createElement("button", { type: "button", "data-id": item.id, onClick: props.triggerSubgroup, className: "c-Navigation__groupanchor c-Navigation__groupanchor--back" },
                        React.createElement(Text, { field: itemTitle }),
                        React.createElement(ArrowLeft, { className: "c-Navigation__arrowleft" })),
                    React.createElement(Group, { groups: item.fields.groups, onBlurHandler: props.onBlurHandler }))));
        }
        else {
            return (item.fields.link && (React.createElement("li", { key: item.id, className: "c-Navigation__item" },
                React.createElement(CustomLink, { data: item.fields.link.value, itemTitle: itemTitle, onFocusHandler: props.onFocusHandler }))));
        }
    }));
}
function Group(props) {
    var groupClass = "c-Navigation__group c-Navigation__group" + (props.groups.length < 3 ? "--huge" : "--cols");
    return (React.createElement("ul", { className: groupClass }, props.groups &&
        props.groups
            // .filter((group) => group.children && group.children.length > 0)
            .map(function (group) {
            return (React.createElement(SubGroup, { onBlurHandler: props.onBlurHandler, key: group.id, subGroup: group, step: 1, count: props.groups.length }));
        })));
}
function SubGroup(props) {
    var titleClass = "c-Navigation__title" + (props.step ? " color-txt--step" + props.step : "");
    var subgroupClass = "c-Navigation__subgroup c-Navigation__subgroup" + (props.count < 3 ? "--huge" : "--cols");
    return (React.createElement("li", { className: subgroupClass },
        React.createElement("span", { className: titleClass },
            React.createElement(Text, { field: props.subGroup.fields.groupName })),
        React.createElement("ul", { className: "c-Navigation__column" }, props.subGroup.fields.groupLinks &&
            props.subGroup.fields.groupLinks.map(function (item) {
                var itemTitle = { value: item.fields.link.value.text };
                return (React.createElement("li", { key: item.id, className: "c-Navigation__subitem" },
                    React.createElement(NavLink, { activeClassName: "is-active", to: item.fields.link.value.href, className: "c-Navigation__anchor", onBlur: function (event) { return props.onBlurHandler(event); } },
                        React.createElement(Text, { field: itemTitle }))));
            }))));
}
function LogoElement(props) {
    return (React.createElement("div", { className: props.className },
        React.createElement(Logo, { currentLanguage: props.currentLanguage })));
}
function Logo(props) {
    var srcImg = props.img || LogoAsset;
    return (React.createElement(NavLink, { to: "/" + props.currentLanguage.toLowerCase(), className: "c-Navigation__logo" },
        React.createElement("img", { src: srcImg, alt: "Infinity Group" })));
}
function _setLocationPath(props) {
    if (typeof window !== "undefined") {
        if (props.currentLanguage !== props.language) {
            // Other Pages
            if (window.location.pathname !== "/") {
                if (window.location.pathname.startsWith("/" + props.currentLanguage)) {
                    return window.location.pathname.replace("/" + props.currentLanguage, "/" + props.language);
                }
                else {
                    // If pathanme doesn't include language ID
                    if (window.location.hostname.includes('infinity-group.pl'))
                        window.location.pathname = "/" + props.currentLanguage + window.location.pathname;
                    return window.location.pathname;
                }
            }
            // Homepage
            return "/" + props.language;
        }
        // Current language
        return window.location.pathname;
    }
    // fallback on init SSR
    return "/" + props.language;
}
// inject dictionary props (`t`) into navigation so we can translate it
// NOTE: using this is needed instead of using i18next directly to keep
// the component state updated when i18n state (e.g. current language) changes
var NavigationWithTranslation = withTranslation()(Navigation);
var graphqlConfiguration = {
    name: "navigationLanguagesQuery",
};
export { LogoElement };
export default GraphQLData(NavigationLanguagesQuery, graphqlConfiguration)(NavigationWithTranslation);
