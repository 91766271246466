var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { withTranslation } from "react-i18next";
// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// internal_modules
import PeopleList from "./GalleryPeopleList";
// fragments
import "./styles.scss";
var GalleryPeople = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, people = _a.people;
    return (React.createElement(PlaceholderComponent, __assign({}, props),
        React.createElement("div", { className: "c-GalleryPeople" },
            heading && (React.createElement(ComponentHeading, { className: "align-center", tag: headingTag, field: heading })),
            people && (React.createElement(PeopleList, { data: people, currentLanguage: props.i18n.language })))));
};
var GalleryPeopleWithTranslation = withTranslation()(GalleryPeople);
export default GalleryPeopleWithTranslation;
