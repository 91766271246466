import React, { useEffect, Fragment } from "react";
import { withRouter, useLocation } from "react-router-dom";

const ScrollTop = ({ children }) => {
  const { pathname } = useLocation();
  const { hash } = useLocation();

  const fullPath = pathname + hash;

    useEffect(() => {
      if (!fullPath.includes('#')) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }, [fullPath]);

  return <Fragment>{children}</Fragment> || null;
};

export default withRouter(ScrollTop);
