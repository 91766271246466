// node_modules
import React from "react";
import { RichText, getFieldValue } from "@sitecore-jss/sitecore-jss-react";

// Shared components
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import BtnElevator from "../../shared/BtnElevator";
import { themeClassName } from "../../helpers/app/jss/fields/Theme";
import { bgGradientFromConfiguration } from "../../helpers/app/jss/fields/Gradient";
// TODO: replace to import {themeClassName} from '../../helpers/app/utils/fields/Theme'; after implement theme configuration
import fieldImage from "../../helpers/app/jss/fieldTypes/Image";
import { generalLinkIsEmpty } from "../../helpers/app/jss/fieldTypes/GeneralLink";

// Partials of component
import "./styles.scss";

const IntroBannerCta = (props) => {
  const { fields } = props;

  let _bgimgurl = getFieldValue(fields, "bgimgurl");
  _bgimgurl = fieldImage(_bgimgurl);
  let _componentwithoutspaces = getFieldValue(fields, "componentwithoutspaces");
  _componentwithoutspaces =
    typeof _componentwithoutspaces !== "undefined"
      ? _componentwithoutspaces
      : true;
  const _heading = getFieldValue(fields, "heading");
  const _description = getFieldValue(fields, "description");
  const _descriptionBottom = getFieldValue(fields, "descriptionBottom");
  const _button = getFieldValue(fields, "button");
  const _label = getFieldValue(fields, "label");
  const fieldNoFollow = getFieldValue(fields, "noFollow");

  // Get value (class gradient step) from 'bgColorGradientStep' field
  const _bgColorGradientStepFields =
    fields.bgColorGradientStep && fields.bgColorGradientStep.fields
      ? fields.bgColorGradientStep
      : null;
  let _bgGradientClassName = "";
  if (_bgColorGradientStepFields) {
    const _gradientFieldValue = bgGradientFromConfiguration(
      _bgColorGradientStepFields
    );
    _bgGradientClassName = _gradientFieldValue.value; // gradient class
  }
  // Get class for color texts from 'theme' field
  const _themeClassName = themeClassName(fields);

  const divParentClasses = `c-IntroBannerCta${_themeClassName} align-center`;
  const divParentStyle = {
    backgroundImage: _bgimgurl.backgroundSrc,
  };
  const $component = {
    backgroundCover: !!_bgimgurl.hasBackground,
    backgroundClass: _bgGradientClassName,
    elementClasses: divParentClasses,
    elementStyle: divParentStyle,
  };

  const withBg = !!(_bgimgurl.hasBackground || _bgGradientClassName !== "");
  const buttonTheme = _bgGradientClassName !== "" ? "light" : "dark";

  return (
    <PlaceholderComponent
      withContainer={false}
      withBg={withBg}
      withoutSpaces={_componentwithoutspaces}
      componentSettings={$component}
      {...props}
    >
      <div className="c-IntroBannerCta__content">
        {_label && (
          <RichText
            tag="span"
            field={fields.label}
            className="c-IntroBannerCta__label"
          />
        )}
        {_heading && (
          <ComponentHeading
            className="align-center"
            tag={fields.headingTag}
            field={fields.heading}
          />
        )}
        {_description && (
          <RichText
            field={fields.description}
            tag="div"
            className="c-IntroBannerCta__description"
          />
        )}
        {!generalLinkIsEmpty(_button) && (
          <div className="c-IntroBannerCta__button">
            <BtnElevator field={_button} theme={buttonTheme} noFollow = {fieldNoFollow}/>
          </div>
        )}
        {_descriptionBottom && (
          <RichText
            field={fields.descriptionBottom}
            tag="div"
            className="c-IntroBannerCta__description"
          />
        )}
      </div>
    </PlaceholderComponent>
  );
};

export default IntroBannerCta;
