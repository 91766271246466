import React from "react";
import { Route, Redirect } from "react-router";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";

const RedirectCustom = (props) => (
  <RedirectToRoute routeData={props.routeData} />
);

function RedirectToRoute({ routeData }) {
  const redirectTo = getFieldValue(
    routeData.sitecore.route.fields,
    "redirectTo"
  );

  if (redirectTo && redirectTo.href) {
    if (redirectTo.href.startsWith("/")) {
      return (
        <Route>
          <Redirect to={redirectTo.href} />
        </Route>
      );
    }
    return (
      typeof window !== "undefined" && (
        <Route
          exact
          path={`/${routeData.sitecore.route.name}`}
          render={() => {
            window.location.assign(redirectTo.href);
            return null;
          }}
        />
      )
    );
  }
}

export default RedirectCustom;
