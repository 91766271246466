/* eslint-disable import/first */
import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
// import { cutText } from '../../../helpers/app/utils/data-types/string';
import "./styles.scss";
var CarouselTile = function (props) {
    var _a = props.fields, brandName = _a.brandName, brandDescription = _a.brandDescription, brandLink = _a.brandLink;
    return (React.createElement("article", { className: "c-CarouselTile c-CarouselTile--white" },
        React.createElement("div", { className: "c-CarouselTile__innerContent" },
            React.createElement(Tile, { href: brandLink },
                brandName && (React.createElement(RichText, { field: brandName, className: "c-CarouselTile__title" })),
                brandDescription && (React.createElement(RichText, { field: brandDescription, className: "c-CarouselTile__text" }))
            // <p className="c-CarouselTile__text">
            //   {cutText(_brandDescription)}
            // </p>
            ))));
};
var Tile = function (props) {
    if (props.href && props.href.value) {
        return (React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: props.href.value.href, title: props.href.value.text }, props.children));
    }
    return props.children;
};
export default CarouselTile;
