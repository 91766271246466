// node_modules
import React from "react";
import {
  getFieldValue,
  RichText,
  Text,
  Link,
} from "@sitecore-jss/sitecore-jss-react";
// const domHelpersClosest = require('dom-helpers/query/closest');
// import domHelpers from 'dom-helpers';

// Shared components
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import BtnElevator from "../../shared/BtnElevator";
import { headingTagField } from "../../shared/Component/ComponentHeading";
import { bgGradientFromConfiguration } from "../../helpers/app/jss/fields/Gradient"; // TODO: replace to import {themeClassName} from '../../helpers/app/utils/fields/Theme'; after implement theme configuration
import {
  generalLinkIsEmpty
} from "../../helpers/app/jss/fieldTypes/GeneralLink";
import { getClosest } from "../../helpers/app/utils/dom";
import { debounce } from "../../helpers/app/utils/debounce";

// Partials of component
import ArrowLeft from "../../assets/icons/ArrowLeft";
import ArrowRight from "../../assets/icons/ArrowRight";
import "./styles-M.scss";
import "./styles-S.scss";

export default class ProductDeliveryProcess extends React.Component {
  constructor(props) {
    super(props);

    // TODO: refactor collapseHandler and use state component
    // this.state = {
    //     activeItemId: '',
    //     activeTarget: null
    // };

    this.state = {
      isMobileOrTablet: false,
      autoplay: true,
      autoplayRemainingTime: this.autoplayTime,
      collapsed: false, //if numberBox is clicked, set collapsed to true to prevent further autoplay and animations
    };

    this.mounted = false;

    this.settings = {
      showsteps: true, // TODO: po poprawkach zamienic na getFieldValue(fields, 'showsteps');,
    };

    this.intervalId = "";

    this.progressBarMaxWidth = 190;
    this.autoplayTime = 4000;

    this.productDeliveryProcess = React.createRef();

    this.handleNumberBox = this.handleNumberBox.bind(this);
    this.handleArrow = this.handleArrow.bind(this);
    this.automaticSlide = this.automaticSlide.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.collapseHandler = this.collapseHandler.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onMouseEnter(event, id) {
    event.persist();
    onHover(event, "onMouseEnter");
  }
  onMouseLeave(event, id) {
    event.persist();
    onHover(event, "onMouseLeave");
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.mounted = true;

      if (this.mounted) {
        this.setState({ isMobileOrTablet: window.innerWidth < 1201 });
      }

      const resize = debounce(() => {
        if (this.mounted) {
          this.setState({ isMobileOrTablet: window.innerWidth < 1201 });
        }
      }, 50);
      window.addEventListener("resize", resize);
    }
    // Initialize automatic slide
    if (this.state.autoplay === true) {
      this.automaticSlide();
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      this.mounted = false;

      const resize = debounce(() => {
        this.setState({ isMobileOrTablet: window.innerWidth < 1201 });
      }, 50);

      window.removeEventListener("resize", resize);
    }
    clearInterval(this.intervalId);
  }

  automaticSlide() {
    const rightArrow =
      this.productDeliveryProcess.current.parentNode.querySelector(
        ".process-info__btn--arrow-right"
      );
    const event = {
      target: rightArrow,
      currentTarget: rightArrow,
    };
    let intervalId = setInterval(() => {
      //Check if autoplay is set to true and if mobile then don't autoplay
      if (
        this.state.autoplay &&
        !this.state.isMobileOrTablet &&
        !this.state.collapsed
      ) {
        this.changeCategory(event);
      }
    }, this.state.autoplayRemainingTime);

    this.intervalId = intervalId;
  }

  pauseSlide() {
    clearInterval(this.intervalId);
    this.setState({ autoplay: false });
  }

  mouseEnter(event) {
    const $parentComponent = _activeParentComponent(event.target);
    const $items = document.querySelectorAll(".process-info__number--loading");
    const $lastItem = $items[$items.length - 1];
    const $firstItem = $items[0];
    const activeElement = $parentComponent.querySelector(
      ".process-info__number--loading.is-active"
    );
    let progressBarWidth = parseInt(getComputedStyle(activeElement).width);
    if (
      $parentComponent.querySelector(
        ".process-info__number--loading.is-active"
      ) === $lastItem
    ) {
      if (progressBarWidth === 0) {
        progressBarWidth = this.progressBarMaxWidth / 2;
      }
      progressBarWidth += parseInt(getComputedStyle($firstItem).width);
      $firstItem.classList.add("paused");
    }

    $parentComponent
      .querySelector(".process-info__number--loading.is-active")
      .classList.add("paused");

    let time =
      this.autoplayTime -
      (progressBarWidth / this.progressBarMaxWidth) * this.autoplayTime;
    this.setState({ autoplayRemainingTime: time });

    if (this.state.autoplay) {
      this.pauseSlide();
    }
  }
  mouseLeave(event) {
    const $parentComponent = _activeParentComponent(event.target);
    const $items = document.querySelectorAll(".process-info__number--loading");
    const $lastItem = $items[$items.length - 1];
    const $firstItem = $items[0];

    if (
      $parentComponent.querySelector(
        ".process-info__number--loading.is-active"
      ) === $lastItem
    ) {
      $firstItem.classList.remove("paused");
    }
    if (!this.state.collapsed) {
      $parentComponent
        .querySelector(".process-info__number--loading.is-active")
        .classList.remove("paused");
    }
    if (!this.state.autoplay) {
      this.setState({ autoplay: true });
    }
    this.automaticSlide();
  }

  handleNumberBox(event, _id) {
    event.persist();

    const $parentComponent = _activeParentComponent(event.target);

    const loadingBar = $parentComponent.querySelectorAll(
      ".process-info__number--loading"
    );
    loadingBar.forEach((item) => item.classList.add("paused"));

    this.setState({
      autoplayRemainingTime: 4000,
      autoplay: false,
      collapsed: true,
    });

    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      this.collapseHandler(event, _id);
    } else if (event.keyCode === 37 || event.keyCode === 39) {
      event.preventDefault();
      this.changeCategory(event);
    }
  }
  handleArrow(event) {
    event.persist();
    const $parentComponent = _activeParentComponent(event.target);

    const loadingBar = $parentComponent.querySelectorAll(
      ".process-info__number--loading"
    );
    loadingBar.forEach((item) => item.classList.add("paused"));

    const $activeComponent = $parentComponent.querySelector(
      ".process-details.is-active"
    );
    this.setState({
      autoplayRemainingTime: 4000,
      autoplay: false,
      collapsed: true,
    });

    if (event.keyCode === 37 || event.keyCode === 39) {
      event.preventDefault();
      this.changeCategory(event);
    } else if (event.key === "Tab") {
      event.preventDefault();
      let focusedElement = $activeComponent.querySelector("a");
      focusedElement.focus();
    }
  }

  collapseHandler = (event, id) => {
    event.persist();

    const $parentComponent = _activeParentComponent(event.target);

    // Deactive all active elememnts
    const activeProcessesList = [
      ...$parentComponent.querySelectorAll(".is-active"),
    ];

    if (activeProcessesList.length > 0) {
      activeProcessesList.forEach((activeProcess) => {
        activeProcess.classList.remove("is-active");
      });
    }
    // Active clicked element
    _setActiveElement(event.target, id);
    if (this.state.isMobileOrTablet) {
      $parentComponent.querySelector(".is-active");
      // .scrollIntoView(true);
    }

    // Clear timer if item was clicked manually
    clearInterval(this.intervalId);
    this.setState({
      autoplayRemainingTime: 4000,
      autoplay: false,
      collapsed: true,
    });
    $parentComponent
      .querySelector(".process-info__number--loading.is-active")
      .classList.add("paused");

    //this.automaticSlide();
  };

  changeCategory = (event) => {
    const $parentComponent = _activeParentComponent(event.target);
    const leftArrow = $parentComponent.querySelector(
      ".process-info__btn--arrow-left"
    );
    const rightArrow = $parentComponent.querySelector(
      ".process-info__btn--arrow-right"
    );

    // Get all items with data-id-heading attribute and store its value in 'itemId' array
    const items = $parentComponent.querySelectorAll(`[data-id-heading]`);
    const itemId = [];

    items.forEach((item) => itemId.push(item.dataset.idHeading));

    // Get active item
    const activeItem = $parentComponent.querySelector(
      `[data-id-heading].is-active`
    );
    const activeItemId = activeItem.dataset.idHeading;

    // Check index of activeItem in 'itemId' array
    let indexOfActiveItem = itemId.indexOf(activeItemId);

    const itemsLength = itemId.length - 1;

    // Check which arrow was clicked and update activeItem index
    if (event.currentTarget === leftArrow) {
      if (indexOfActiveItem === 0) {
        indexOfActiveItem = itemsLength;
      } else {
        indexOfActiveItem--;
      }
    } else if (event.currentTarget === rightArrow) {
      if (indexOfActiveItem === itemsLength) {
        indexOfActiveItem = 0;
      } else {
        indexOfActiveItem++;
      }
    }

    // Deactive all active elememnts
    const activeProcessesList = [
      ...$parentComponent.querySelectorAll(".is-active"),
    ];

    if (activeProcessesList.length > 0) {
      activeProcessesList.forEach((activeProcess) => {
        activeProcess.classList.remove("is-active");
      });
    }
    // Active clicked element

    _setActiveElement(event.target, itemId[indexOfActiveItem]);
    // Clear timer if item was clicked manually
    clearInterval(this.intervalId);
    this.setState({ autoplayRemainingTime: 4000 });
    this.automaticSlide();
  };

  productHanlder = (product, productIndex) => {
    const productFields = product.fields;

    const _navigationId =
      getFieldValue(productFields, "navigationId") || productIndex;
    const _themeFields =
      productFields.theme && productFields.theme.fields
        ? productFields.theme
        : null;

    let _themeClassName = "";
    let _themeClassNameTextGradient = "";
    if (_themeFields) {
      const _theme = bgGradientFromConfiguration(_themeFields);
      _themeClassName = _theme.value; // gradient class
      _themeClassNameTextGradient = _theme.textGradient;
    }
    const _id = product.id + _navigationId;
    const _key = "process-item-" + _id;

    // TODO: change to component state
    const _isActiveClassName = productIndex === 0 ? "is-active" : "";

    return [
      this.navigationOrHeaderItem(
        _isActiveClassName,
        product,
        _navigationId,
        _themeClassName
      ),
      <dd
        key={_key}
        data-id-content={_id}
        className={`process-details ${_isActiveClassName}`}
        onMouseEnter={(event) => this.mouseEnter(event)}
        onMouseLeave={(event) => this.mouseLeave(event)}
      >
        {this.detailsHandler(productFields, _themeClassNameTextGradient)}
        {this.sidebarHandler(productFields, _themeClassName)}
      </dd>,
    ];
  };

  detailsHandler = (productFields, themeClassName) => {
    const _heading = getFieldValue(productFields, "heading");
    const _description = getFieldValue(productFields, "description");
    const _showMoreLink = getFieldValue(productFields, "showMoreLink");

    return (
      <section className="process-details__text">
        {_heading && (
          <Text
            field={productFields.heading}
            tag={headingTagField(productFields.headingTag) || "h3"}
            className={`process-details__text__title ${themeClassName}`}
          />
        )}
        {_description && (
          <RichText
            field={productFields.description}
            className="process-details__text__description"
          />
        )}
        {_showMoreLink && (
          <BtnElevator
            field={_showMoreLink}
            className="process-details__text__more"
          />
        )}
      </section>
    );
  };

  sidebarHandler = (productFields, themeClassName) => {
    const _titleSidebar = getFieldValue(productFields, "titleSidebar");

    return (
      <section className="process-details__includedElements">
        {_titleSidebar && (
          <Text
            field={productFields.titleSidebar}
            tag={headingTagField(productFields.titleSidebarTag) || "h4"}
            className="process-details__includedElements__title"
          />
        )}
        {productFields.sidebarLinks && productFields.sidebarLinks.length > 0 && (
          <ul className="reset">
            {productFields.sidebarLinks.map((linkItem, linkIndex) => {
              const _keyLink = linkItem.id + linkIndex;
              linkItem = linkItem.fields;

              return (
                <li key={_keyLink}>
                  <SidebarLink
                    customClass={`reset process-details__includedElement color-txt--white ${themeClassName}`}
                    fields={linkItem}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </section>
    );
  };

  navigationOrHeaderItem = (
    isActiveClassName,
    product,
    navigationId,
    themeClassName
  ) => {
    const productFields = product.fields;
    const _id = product.id + navigationId;

    navigationId =
      parseInt(navigationId) < 10 ? `0${navigationId}` : navigationId;

    const _idElement = "process-info-" + _id;
    const _navigationTitle = getFieldValue(productFields, "navigationTitle");

    return (
      <dt
        key={_idElement}
        data-id-heading={_id}
        className={`process-info ${isActiveClassName}`}
        role="button"
        tabIndex="0"
        onClick={(event) => this.collapseHandler(event, _id)}
        onKeyDown={(event) => this.handleNumberBox(event, _id)}
        //onMouseLeave={(event) => this.mouseLeave(event)}
        // onMouseEnter={(event) => this.onMouseEnter(event, _idElement)}
        // onMouseLeave={(event) => this.onMouseLeave(event, _idElement)}
      >
        <span
          className={`process-info__number ${themeClassName} ${isActiveClassName}`}
        >
          {navigationId}
        </span>
        {/* <span className={`process-info__number--loading-firstItem  ${isActiveClassName}`}></span> */}
        <span
          className={`process-info__number--loading  ${isActiveClassName}`}
        />
        <span className="process-info__name">{_navigationTitle}</span>
        {/* FIXME: dodać tekst zwin/rozwin dla ikonki - potrzebne do WCAG */}
        <ArrowLeft className="process-info__btn--collapse" aria-hidden="true" />
        {/* <span className="process-info__btn--collapse" aria-hidden='true'> */}
        {/* <span className="sr-only">{_navigationTitle}</span> */}
        {/* </span> */}
      </dt>
    );
  };

  render() {
    // console.log('ProductDeliveryProcess', this.props);
    const { fields } = this.props;
    const { showsteps } = this.settings;
    const _heading = getFieldValue(fields, "heading");

    return (
      <PlaceholderComponent {...this.props}>
        <ArrowLeft
          className="process-info__btn--arrow process-info__btn--arrow-left"
          tabIndex="0     "
          onClick={(event) => this.changeCategory(event)}
          onKeyDown={(event) => this.handleArrow(event)}
        />
        <ArrowRight
          className="process-info__btn--arrow process-info__btn--arrow-right"
          tabIndex="0"
          onClick={(event) => this.changeCategory(event)}
          onKeyDown={(event) => this.handleArrow(event)}
        />
        <section
          ref={this.productDeliveryProcess}
          className="c-ProductDeliveryProcess"
        >
          {_heading && (
            <ComponentHeading
              className="align-center"
              tag={fields.headingTag}
              field={fields.heading}
              headingIsHidden={fields.headingIsHidden}
            />
          )}

          {
            fields.productsDelivery &&
            fields.productsDelivery.length > 0 &&
            showsteps ? (
              <dl
                className="c-ProductDeliveryProcess__processes"
                //onMouseEnter={(event) => this.mouseEnter(event)}
                //onMouseLeave={(event) => this.mouseLeave(event)}
              >
                {fields.productsDelivery.map((product, productIndex) =>
                  this.productHanlder(product, productIndex)
                )}
              </dl>
            ) : null
            // (
            //     // TODO: dodać wariant z ukrytą nawigacją
            //     <div>
            //         {fields.productsDelivery.map(product => this.productHanlder(product.fields))}
            //     </div>
            // )
          }
        </section>
      </PlaceholderComponent>
    );
  }
}

function onHover(event, eventName) {
  // console.log('event', event)
  // const $idItem = `#${id}`;
  // const $btnElemParent = document.querySelector(idItem);
  // const $desktopBtnInfo = document.querySelector(`${idItem} > .process-info__number`);
  // if (!$btnElemParent.classList.contains('is-active')) {
  //     if (eventName === "onMouseEnter") {
  //         $desktopBtnInfo.classList.add('is-active');
  //     }
  //     else if (eventName === "onMouseLeave") {
  //         $desktopBtnInfo.classList.remove('is-active');
  //     }
  // }
}

function setProgressBarAnimations(startAnimation) {
  const $items = document.querySelectorAll(".process-info__number--loading");
  const $lastItem = $items[$items.length - 1];
  const $firstItem = $items[0];
  if (startAnimation) {
    $lastItem.classList.add("last-item-animation");
    $firstItem.classList.add("first-item-animation");
  } else {
    $lastItem.classList.remove("last-item-animation");
    $firstItem.classList.remove("first-item-animation");
  }
}

function _setActiveElement($triggerItem, id) {
  const $parentComponent = _activeParentComponent($triggerItem);
  const $heading = $parentComponent.querySelector(`[data-id-heading="${id}"]`);
  const $content = $parentComponent.querySelector(`[data-id-content="${id}"]`);
  //const $items = $parentComponent.querySelectorAll('dt')
  const $items = $parentComponent.querySelectorAll(
    ".process-info__number--loading"
  );
  const $lastItem = $items[$items.length - 1];

  // Active clicked element
  $heading.classList.add("is-active");
  $heading.querySelector(".process-info__number").classList.add("is-active");
  $heading
    .querySelector(".process-info__number--loading")
    .classList.add("is-active");
  $content.classList.add("is-active");

  if ($lastItem.classList.contains("is-active")) {
    setProgressBarAnimations(true);
  } else {
    setProgressBarAnimations(false);
  }
}

function _activeParentComponent($triggerItem) {
  return getClosest($triggerItem, ".component");
}

function SidebarLink(props) {

  const { fields } = props;

  const linkData = getFieldValue(fields, "link");

  if (linkData && !generalLinkIsEmpty(linkData)) {
    return <Link field={fields.link} className={props.customClass} />;
  } else {
    return (
        <Text field={fields.text} className={props.customClass} tag="span" />
    );
  }

}
