var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React, { useContext } from "react";
import { Text, Link, getFieldValue } from "@sitecore-jss/sitecore-jss-react";
import ContactVisibility from '../../store/ContactVisibility/ContactVisibility';
import "./styles.scss";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
var ContactSection = function (props) {
    var fields = props.fields;
    var textForPopupButton = getFieldValue(props.fields, "textForPopupButton");
    var divParentStyle = {
        backgroundImage: "url(" + fields.bgimgurl.value.src + ")",
    };
    var divParentClasses = "c-ContactSection align-left";
    var popupIsVisible = useContext(ContactVisibility);
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, withoutSpaces: true }, props),
        React.createElement("div", { className: divParentClasses, style: divParentStyle },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "container__inner" },
                    React.createElement("div", { className: "c-ContactSection__text" },
                        React.createElement("p", { className: "c-ContactSection__text__heading" },
                            fields.heading && React.createElement(Text, { field: fields.heading, tag: "span" }),
                            textForPopupButton
                                ? React.createElement("a", { rel: "nofollow", role: "button", href: "#", className: "clip-text--gradient-base", onClick: popupIsVisible.contactPopup.onShowPopup }, textForPopupButton)
                                : React.createElement(Link, { field: props.fields.link, className: "clip-text--gradient-base" })),
                        fields.description && (React.createElement(Text, { field: fields.description, tag: "p", className: "c-ContactSection__text__description" }))))))));
};
export default ContactSection;
