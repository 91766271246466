// node_modules
import React, { useState, useEffect, } from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import { useCookies } from "react-cookie";
// local_modules
import { popupsWrapper } from "../../helpers/app/components/popups-wrapper";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// fragments
import "./styles.scss";
var Cookies = function (props) {
    var fields = props.fields;
    var heading = fields.heading, headingTag = fields.headingTag, headingIsHidden = fields.headingIsHidden, content = fields.content;
    var acceptCookies = function (event) {
        event.persist();
        var DAYS = 365;
        var date = new Date();
        date.setTime(date.getTime() + DAYS * 24 * 60 * 60 * 1000);
        setCookie("cookies-ig", "1", { path: "/", expires: date });
        setHiddenCookies(true);
    };
    var manageDisplayCookie = function () {
        var openPopup = acceptedCookies ? false : true;
        popupsWrapper(openPopup);
    };
    var checkCookie = function () {
        var readCookie = cookies["cookies-ig"];
        if (typeof readCookie === "undefined" || readCookie === null) {
            readCookie = false;
        }
        else {
            if (readCookie === "1") {
                readCookie = true;
            }
            else {
                readCookie = false;
            }
        }
        return readCookie;
    };
    // state
    var _a = useCookies(["cookies-ig"]), cookies = _a[0], setCookie = _a[1];
    var _b = useState(function () { return checkCookie(); }), acceptedCookies = _b[0], setAcceptedCookies = _b[1];
    var _c = useState(false), hiddenCookies = _c[0], setHiddenCookies = _c[1];
    var _d = useState(false), isShown = _d[0], setIsShown = _d[1];
    // component (componentDidMount)
    useEffect(function () {
        setTimeout(function () {
            setIsShown(true);
        }, 1500);
    });
    useEffect(function () {
        var hideCookies = function () {
            setAcceptedCookies(true);
            popupsWrapper(false);
        };
        if (hiddenCookies !== false) {
            setTimeout(function () {
                hideCookies();
            }, 300); // animation-duration of .animation--slideDown
        }
    }, [hiddenCookies, setAcceptedCookies]);
    // component (render)
    var cookiesClasses = "color-bg--light1 popup component component--nospaces";
    if (hiddenCookies) {
        cookiesClasses += " animation--slideDown"; // when click on accept cookies
    }
    if (isShown) {
        cookiesClasses += " is-show animation--slideUp";
        manageDisplayCookie();
    }
    return !acceptedCookies ? (React.createElement("section", { id: "cookies", className: cookiesClasses },
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "container__inner" },
                React.createElement("button", { id: "cookies__accept", type: "button", onClick: function (event) { return acceptCookies(event); } },
                    React.createElement("span", { "aria-hidden": "true", id: "cookies__accept__icon" }),
                    React.createElement("span", { className: "sr-only" }, "OK")),
                React.createElement(ComponentHeading, { tag: headingTag, field: heading, headingIsHidden: headingIsHidden }),
                React.createElement(RichText, { tag: "div", id: "cookies__content", field: content }))))) : null;
};
export default Cookies;
