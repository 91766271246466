import React from "react";
import {
  Text,
  RichText,
  getFieldValue,
} from "@sitecore-jss/sitecore-jss-react";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import { themeClassName } from "../../helpers/app/jss/fields/Theme";
import fieldImage from "../../helpers/app/jss/fieldTypes/Image";

import "./styles.scss";

const IntroBanner = (props) => {
  // console.log('IntroBanner', props)
  const { fields } = props;

  // Geting data from sitecore fields
  let _bgimgurl = getFieldValue(fields, "bgimgurl");
  _bgimgurl = fieldImage(_bgimgurl);

  const _topTitle = getFieldValue(fields, "topTitle");
  const _middleTitle = getFieldValue(fields, "middleTitle");
  const _bottomTitle = getFieldValue(fields, "bottomTitle");

  const _heading = getFieldValue(fields, "heading");
  const _description = getFieldValue(fields, "description");
  const _signature = getFieldValue(fields, "signature");

  // Component variants
  // FIXME: zredukować ilość warunków do sprawdzenia, które dodają style wariantów
  const divParentStyle = {
    backgroundImage: _bgimgurl.backgroundSrc,
  };
  const _variantComponent_1 =
    _topTitle && _middleTitle && _bottomTitle ? " c-IntroBanner--variant1" : "";
  const _variantComponent_2 =
    _heading && _signature ? " c-IntroBanner--variant2" : "";
  const _variantComponent_3 =
    _heading && _description ? " c-IntroBanner--variant3" : "";

  // Root classes of component
  const _themeClassName = themeClassName(fields);
  const divParentClasses = `c-IntroBanner${_themeClassName}${_variantComponent_1}${_variantComponent_2}${_variantComponent_3} align-center`;

  // Component properties
  const $component = {
    backgroundCover: !!_bgimgurl.hasBackground,
    elementClasses: divParentClasses,
    elementStyle: divParentStyle,
  };

  return (
    <PlaceholderComponent
      withContainer={false}
      componentSettings={$component}
      {...props}
    >
      <div className="c-IntroBanner__text">
        {/* Heading in 3 lines */}
        {_topTitle && (
          <RichText
            field={fields.topTitle}
            tag="span"
            className="c-IntroBanner__text__topTitle"
          />
        )}
        {_middleTitle && (
          <RichText
            field={fields.middleTitle}
            tag="span"
            className="c-IntroBanner__text__middleTitle"
          />
        )}
        {_bottomTitle && (
          <RichText
            field={fields.bottomTitle}
            tag="span"
            className="c-IntroBanner__text__bottomTitle"
          />
        )}

        {/* Heading with description or small signature*/}
        <ComponentHeading
          tag={props.fields.headingTag}
          field={props.fields.heading}
          className="c-IntroBanner__text__heading"
          headingIsHidden={props.fields.headingIsHidden}
        />
        {_description && (
          <RichText
            field={fields.description}
            tag="div"
            className="c-IntroBanner__text__description"
          />
        )}
        {_signature && (
          <Text
            field={fields.signature}
            tag="p"
            className="c-IntroBanner__text__signature"
          />
        )}
      </div>
    </PlaceholderComponent>
  );
};

export default IntroBanner;
