// node_modules
import React from "react";
import { getFieldValue, RichText } from "@sitecore-jss/sitecore-jss-react";

// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import { debounce } from "../../helpers/app/utils/debounce";
import { cleanupEmptyItemsFromArray } from "../../helpers/app/utils/array";

// Partials of component
import "./styles.scss";
export default class ScopeWorkProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileOrTablet: false,
      autoplay: true,
    };

    this.mounted = false;

    this.componentRootRef = React.createRef();
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      this.mounted = true;

      if (this.mounted) {
        this.setState({ isMobileOrTablet: window.innerWidth < 1201 });
      }

      const resize = debounce(() => {
        if (this.mounted) {
          this.setState({ isMobileOrTablet: window.innerWidth < 1201 });
        }
      }, 50);
      window.addEventListener("resize", resize);
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      this.mounted = false;

      const resize = debounce(() => {
        this.setState({ isMobileOrTablet: window.innerWidth < 1201 });
      }, 50);

      window.removeEventListener("resize", resize);
    }
  }

  productHanlder = (product, productIndex) => {
    const { fields } = product;
    const hasDescription = getFieldValue(fields, "description");
    // const productsDeliveryFields = fields.productsDelivery.fields;
    const id = product.id + productIndex;
    const key = "process-item-" + id;
    const isActiveClassName = hasDescription ? "is-active" : "";

    return (
      <div
        className={cleanupEmptyItemsFromArray([
          "c-ScopeWorkProject__item",
          isActiveClassName,
        ])}
        key={`${key}-item-${productIndex}`}
      >
        {/* {this.navigationOrHeaderItem(productsDeliveryFields)} */}
        <dd key={`${key}-${productIndex}`} data-id-content={id}>
          {this.detailsHandler(fields)}
        </dd>
      </div>
    );
  };

  detailsHandler = (fields) => {
    const descriptionFiled = getFieldValue(fields, "description");

    return (
      <div>
        {descriptionFiled && <RichText field={fields.description} tag="p" />}
      </div>
    );
  };

  navigationOrHeaderItem = (productsDeliveryFields) => {
    // const { theme } = productsDeliveryFields;
    const themeClassName = ''; /*getFieldValue(theme.fields, "value");*/
    const nameScope = getFieldValue(productsDeliveryFields, "navigationTitle");

    const navigationId = getFieldValue(productsDeliveryFields, "navigationId");
    const idElement = "process-info-" + navigationId;
    const scopeWorkId =
      parseInt(navigationId) < 10 ? `0${navigationId}` : navigationId;

    return (
      <dt key={idElement} data-id-heading={navigationId}>
        <span className={`${themeClassName}`}>{scopeWorkId}</span>
        <br />
        <span>{nameScope}</span>
      </dt>
    );
  };

  render() {
    const { fields } = this.props;

    return (
      <PlaceholderComponent {...this.props}>
        <div ref={this.componentRootRef} className="c-ScopeWorkProject">
          <ComponentHeading
            className="align-center"
            tag={fields.headingTag}
            field={fields.heading}
          />

          {fields.scopeOfWork && fields.scopeOfWork.length > 0 ? (
            <dl className="c-ScopeWorkProject__processes">
              {fields.scopeOfWork.map((product, productIndex) =>
                this.productHanlder(product, productIndex + 1)
              )}
            </dl>
          ) : null}
        </div>
      </PlaceholderComponent>
    );
  }
}
