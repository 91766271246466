var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
import React from "react";
import { getFieldValue, } from "@sitecore-jss/sitecore-jss";
import { bgGradientTypeClassName } from "../../helpers/app/jss/fields/Gradient.js";
import withComponentParametersItem from "../../lib/ComponentParametersItem";
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
import BtnElevator from "../../shared/BtnElevator";
import "./styles.scss";
var BannerNextStep = function (props) {
    var fields = props.fields;
    var bgGradientType = fields.gradient
        ? fields.gradient.value
        : bgGradientTypeClassName(fields);
    var step = getFieldValue(fields, "step");
    var fieldNoFollow = getFieldValue(fields, "noFollow");
    var $component = {
        backgroundClass: bgGradientType,
    };
    return (React.createElement(PlaceholderComponent, __assign({ withBg: true, withoutSpaces: true, customSpaces: true, componentSettings: $component }, props),
        React.createElement("div", { className: "c-BannerNextStep" },
            step && (React.createElement("small", null,
                React.createElement("span", { className: "c-BannerNextStep__step" }, "0" + step))),
            fields.heading && (React.createElement(ComponentHeading, { tag: fields.headingTag, field: fields.heading, className: "c-BannerNextStep__heading", headingIsHidden: fields.headingIsHidden })),
            fields.button && (React.createElement("p", { className: "c-BannerNextStep__button" },
                React.createElement(BtnElevator, { field: fields.button.value, theme: "light", noFollow: fieldNoFollow }))))));
};
// export default BannerNextStep;
// export default withComponentParametersData({ name: 'connectedQuery' })(BannerNextStep);
export default withComponentParametersItem({ name: "connectedQuery" })(BannerNextStep);
