import React from "react";
import { RichText, getFieldValue } from "@sitecore-jss/sitecore-jss-react";

import "./styles.scss";

const ComponentHeading = (props) => {
  let className = "component__heading";
  const headingIsHidden = getFieldValue(props, "headingIsHidden");

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (headingIsHidden) {
    className += " sr-only";
  }
  return (
    <RichText
      className={className}
      tag={headingTagField(props.tag) || "h2"}
      field={props.field}
    />
  );
};

/**
 * @description retrun tagName from data\content\Configuration\HeadingTags
 *
 * @export {function}
 * @param {object} field put this: fields.headingTag.fields
 * @returns {string | null} heading tagName (ex. 'h1', 'h2', etc.)
 */
export function headingTagField(field) {
  if (field && field.fields) {
    if (Array.isArray(field)) {
      const headingTagValue = field && field.length > 0 ? field[0] : null;
      if (headingTagValue) {
        return headingTagValue.fields
          ? getFieldValue(headingTagValue.fields, "value")
          : null;
      } else {
        return null;
      }
    } else {
      return field ? getFieldValue(field, "value") : null;
    }
  }
  return null;
}

export default ComponentHeading;
