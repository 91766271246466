var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable import/first */
// node_modules
import React from "react";
import { getFieldValue } from "@sitecore-jss/sitecore-jss-react";
// local_modules
import PlaceholderComponent from "../../shared/Component/ComponentPlaceholder";
import ComponentHeading from "../../shared/Component/ComponentHeading";
// internal_modules
import TilesList from "./ImageTextTilesList";
import "./styles.scss";
var ImageTextTiles = function (props) {
    var _a = props.fields, heading = _a.heading, headingTag = _a.headingTag, headingIsHidden = _a.headingIsHidden, tiles = _a.tiles;
    var divParentClasses = ["c-ImageTextTiles"];
    var variantDisplayTiles = "";
    if (props.fields && props.fields.variantDisplayTiles) {
        var varinatClassValue = getFieldValue(props.fields.variantDisplayTiles, "classname");
        if (varinatClassValue) {
            variantDisplayTiles = "c-ImageTextTiles--" + varinatClassValue;
            divParentClasses.push(variantDisplayTiles);
        }
    }
    var textAlignment = getFieldValue(props.fields, "textAlignment");
    divParentClasses.push(textAlignment);
    var componentSettings = {
        elementClasses: divParentClasses,
    };
    return (React.createElement(PlaceholderComponent, __assign({ withContainer: false, componentSettings: componentSettings }, props),
        heading && (React.createElement(ComponentHeading, { tag: headingTag, field: heading, headingIsHidden: headingIsHidden, className: "align-center" })),
        tiles && React.createElement(TilesList, { data: tiles })));
};
export default ImageTextTiles;
