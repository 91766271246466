import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';

/**
 * @description Method returns classes text color 
 *
 * @param {object | string} fields
 * @returns {string} classes list (ex. ' color-txt--white align-center')
 */
export function themeClassName(fields = '') {
    const theme = (fields.theme && fields.theme.fields) ? getFieldValue(fields.theme.fields, 'value') : '';

    let themeVariantClass = '';
    switch (theme) {
      case "light":
        themeVariantClass = ' color-txt--white';
        break;
      case "dark":
        themeVariantClass = ' color-txt--primary';
        break;
      default:
        themeVariantClass = '';
        break;
    }
    return `${themeVariantClass}`;
}