import React from 'react';
import {graphql} from 'react-apollo';
import {resetExperienceEditorChromes, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {loader as gqlLoader} from 'graphql.macro';

const query = gqlLoader('./ComponentParametersItems.graphql');

/**
 * Assigns custom parameter items with a few fields to this.params.
 *
 * @param configuration
 * @returns {function(*=): (props: any) => JSX.Element}
 */
function withComponentParametersItem(configuration = {}) {
  return function wrapComponent(Component) {
    class ComponentParametersItemWrapper extends React.Component {
      static displayName = `JSSGraphQLComponent(${Component.displayName || Component.name || 'Component'})`;

      render() {
        if (!query) {
          throw new Error(
            'query was falsy in withComponentParametersItem. It should be a GraphQL query from graphql-tag. Perhaps missing graphql-tag/loader?'
          );
        }

        // console.log('ComponentParametersItemWrapper props ', this.props, ComponentParametersItemWrapper.displayName);
        // console.log('ComponentParametersItemWrapper sitecoreContext ', this.props.sitecoreContext);

        const newConfiguration = {...configuration};

        if (!newConfiguration.name) newConfiguration.name = 'data';

        // ensure variables object exists
        newConfiguration.options = newConfiguration.options || {};
        newConfiguration.options.variables = newConfiguration.options.variables || {};

        // if we're in experience editor or preview we need to disable SSR of GraphQL queries
        // because SSR queries are made unauthenticated, so they would have normal mode data = bad
        if (this.props.sitecoreContext && this.props.sitecoreContext.pageState !== 'normal') {
          newConfiguration.options.ssr = false;
        } else if (
          query.definitions.some(
            (def) => def.kind === 'OperationDefinition' && def.operation === 'subscription'
          )
        ) {
          // if the document includes any subscriptions, we also disable SSR as this hangs the SSR process
          // not to mention being quite silly to SSR when they're reactive
          newConfiguration.options.ssr = false;
        }

        // find all variable definitions in the GraphQL query, so we can send only ones we're using
        const variableNames = extractVariableNames(query);
        const paramNames = extractParamsVariables(this.props.params);
        const paramItemsId = unique(getValues(paramNames));

        // console.log('ComponentParametersItemWrapper variableNames', variableNames);
        // console.log('ComponentParametersItemWrapper paramNames', paramNames);
        // console.log('ComponentParametersItemWrapper paramItemsId', paramItemsId);

        if (paramItemsId.length > 0) {
          Object.keys(variableNames)
            .forEach((key, i) => {
              if (paramItemsId[i]) {
                newConfiguration.options.variables[key] = paramItemsId[i];
              }
            });
        }

        // build the props processing function that will set the result object to the name
        newConfiguration.props = (props) => {
          // console.log('newConfiguration.props', props);
          const innerQuery = props[newConfiguration.name];
          let resultProps = {};
          resultProps[newConfiguration.name] = innerQuery;

          // run a user-specified props function too if one exists
          if (configuration.props)
            resultProps = Object.assign(resultProps, configuration.props(props));

          const resultParams = Object.assign(this.props.params || {}, props.params || {});
          Object.keys(variableNames).forEach((key) => {
            const item = innerQuery[key];
            // console.log('innerQuery[key] -- ', key, item);
            if (item) {
              // console.log('key, value', key, props[newConfiguration.name][key]);
              const foundNames = findParamNameFor(paramNames, item.id);
              foundNames.forEach((name) => {
                const resultItem = resultToItem(item);
                if (paramNames[name].length === 1) {
                  resultParams[name] = resultItem;
                } else {
                  // console.log(" resultParams[name]", resultParams[name]);
                  if (!resultParams[name] || !(resultParams[name] instanceof Array)) {
                    resultParams[name] = [];
                  }
                  resultParams[name].push(resultItem);
                }
              });
            }
          });

          resultProps = Object.assign(resultProps, {params: resultParams});

          // console.log('resultProps', resultProps);
          // console.log('resultParams', resultParams);

          return resultProps;
        };

        // console.log('ComponentParametersItemWrapper newConfiguration', newConfiguration);

        const GQL = graphql(query, newConfiguration)(Component);
        // console.log('GQL', GQL);
        return <GQL {...this.props} />;
      }

      // eslint-disable-next-line class-methods-use-this
      componentDidUpdate() {
        resetExperienceEditorChromes();
      }
    }

    return withSitecoreContext()(ComponentParametersItemWrapper);
    // return ComponentParametersItemWrapper;
  };
}

function extractVariableNames(query) {
  const variableNames = {};
  query.definitions
    .map((def) => def.variableDefinitions)
    .filter((def) => def)
    .forEach((defs) =>
      defs.forEach((def) => {
        if (def.kind && def.kind === 'VariableDefinition') {
          variableNames[def.variable.name.value] = true;
        }
      })
    );

  return variableNames;
}

function extractParamsVariables(params) {
  const paramsNames = {};

  if (!params) {
    return paramsNames;
  }

  Object.keys(params)
    .filter((key) => {
      return params.hasOwnProperty(key) && params[key][0] === '{' && params[key][params[key].length - 1] === '}';
    })
    .forEach((key, index) => {
      paramsNames[key] = params[key].split('|');
    });

  return paramsNames;
}

function getValues(obj) {
  let result = [];
  Object.keys(obj)
    .forEach((key, index) => {
      result = result.concat(obj[key]);
    });

  return result;
}

function unique(array) {
  const result = [];

  array.forEach((value) => {
    if (!result.find((v) => v === value)) {
      result.push(value);
    }
  });

  return result;
}

function removeNonWordChars(string) {
  return string.replace(/\W+/g, '');
}

function isIdEqual(id1, id2) {
  return removeNonWordChars(id1).toUpperCase() === removeNonWordChars(id2).toUpperCase();
}

function findParamNameFor(paramNames, id) {
  // console.log('findParamNameFor', paramNames, id);
  const result = [];
  Object.keys(paramNames)
    .filter((key) => {
        return paramNames[key].reduce((previous, current) => {
          return previous || isIdEqual(current, id);
        }, false);
      }
    )
    .forEach((key) => {
      result.push(key);
    });

  // console.log('findParamNameFor', paramNames, id, 'result', result);
  return result;
}

function resultToItem(item) {
  const result = {};

  for (const field of item.fields) {
    result[field.name] = {...field};
  }

  // console.log("resultToItem--", item, "result", result);
  return result;
}

export default withComponentParametersItem;
